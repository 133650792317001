.menu {
  &__list {
    display     : flex;
    flex-wrap   : wrap;
    list-style  : none;
    margin-left : -20px;
    margin-right: -20px;

    @media screen and (max-width: @tablet) {
      display: block;
      margin : 0;
    }
  }

  &__item {
    position: relative;
    padding : 0 20px;

    @media screen and (max-width: @tablet) {
      padding: 0;
    }
  }

  &__link {
    font-size      : 18px;
    line-height    : 1.5;
    font-weight    : 500;
    color          : @color-black;
    position       : relative;
    text-decoration: none;
    transition     : all 0.5s;

    @media screen and (max-width: @tablet) {
      padding   : 10px 5px;
      display   : block;
    }
  }

}

.menu__item:hover {
  @media screen and (min-width: @min-tablet) {
    .menu__link {
      color: @color-red;
    }
  }
}

.menu__link.active {
  color: @color-red;
}