.brand {
  width          : 100%;
  height         : 60px;
  padding        : 10px 20px;
  border         : 1px solid #E0E1E7;
  border-radius  : 10px;
  display        : flex;
  align-items    : center;
  text-decoration: none;

  @media screen and (max-width: @mob) {
    height : 40px;
    border : 0;
    padding: 0;
  }

  &__wrap {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    width          : 100%;
  }

  &__info {
    display    : flex;
    align-items: center;
  }

  &__logo-wrap {
    width    : 40px;
    min-width: 40px;

    @media screen and (max-width: @mob) {
      width    : 30px;
      min-width: 30px;
    }
  }

  &__logo {
    transition: all 0.5s;
    filter    : grayscale(1);
  }

  &__name {
    padding-left: 10px;
    font-size   : 16px;
    font-weight : 500;
    display     : inline-block;
    transition  : all 0.5s;

    @media screen and (max-width: @mob) {
      padding-left: 5px;
      font-size   : 14px;
    }
  }

  &__count {
    color      : #919395;
    font-size  : 16px;
    font-weight: 500;
    display    : inline-block;

    @media screen and (max-width: @mob) {
      font-size: 14px;
    }
  }
}

.brand:hover {
  .brand__logo {
    filter: none;
  }

  .brand__name {
    color: @color-red;
  }
}