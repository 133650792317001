.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--on-popup {
    // overflow: hidden;
    max-width: 100vw;
  }

  &--on-open-menu {
    max-width: 100vw;
    
    .main:before {
      content        : '';
      display        : block;
      width          : 100%;
      height         : 100%;
      background     : rgba(17, 17, 17, 0.5);
      position       : fixed;
      left           : 0;
      top            : 0;
      z-index        : 3;
      // backdrop-filter: blur(3px);
    }
  }
}
.main {
  flex-grow: 1;
}