.car-specification {
  &__tab-nav-list {
    display      : flex;
    border-bottom: 1px solid #E0E1E7;
  }

  &__tab-nav-item {
    padding-right: 40px;

    @media screen and (max-width: @mob) {
      padding-right: 15px;
    }

    @media screen and (max-width: 350px) {
      padding-right: 7px;
    }

    &:last-child {
      padding-right: 0;

      @media screen and (max-width: @mob) {
        padding-right: 0;
      }
    }
  }

  &__tab-btn {
    display       : flex;
    padding-top   : 18px;
    padding-bottom: 14px;
    font-size     : 18px;
    font-weight   : 500;
    line-height   : 1.5;
    color         : #515456;
    position      : relative;

    @media screen and (max-width: @mob) {
      padding-top   : 0;
      padding-bottom: 16px;
      font-size     : 14px;
    }

    @media screen and (max-width: 350px) {
      font-size: 12px;
    }

    &::after {
      content   : '';
      display   : block;
      width     : 100%;
      height    : 3px;
      position  : absolute;
      left      : 0;
      bottom    : -2px;
      background: @color-red;
      transition: all 0.5s;
      opacity   : 0;
    }

    &.js-tabs-btn--active {
      color: @color-red;

      &:after {
        opacity: 1;
      }
    }
  }

  &__tabs-info-wrap {
    padding-top: 40px;

    @media screen and (max-width: @mob) {
      padding-top: 20px;
    }
  }

  &__title {
    font-size     : 28px;
    line-height   : 1.3;
    font-weight   : 700;
    padding-bottom: 20px;

    @media screen and (max-width: @mob) {
      font-size: 20px;
    }
  }

  &__options-list {
    list-style: none;
    columns   : 3 auto; //3 колонки, ширина авто
    column-gap: 30px; //расстояние между колонками

    @media screen and (max-width: @mob) {
      columns: 2 auto;
    }
  }

  &__options-item {
    display       : inline-block;
    padding-bottom: 20px;
    width         : 100%;

    @media screen and (max-width: @mob) {
      padding-bottom: 15px;
    }
  }

  &__option-name {
    display       : block;
    font-size     : 16px;
    line-height   : 1.5;
    color         : @color-gray;
    padding-bottom: 5px;

    @media screen and (max-width: @mob) {
      font-size: 14px;
    }
  }

  &__option-value {
    display    : block;
    font-size  : 18px;
    line-height: 1.5;
    color      : #313335;
    font-weight: 500;

    @media screen and (max-width: @mob) {
      font-size: 16px;
    }
  }

  &__description {
    p {
      font-size     : 16px;
      line-height   : 1.5;
      padding-bottom: 10px;
      color         : @color-gray;

      @media screen and (max-width: @mob) {
        font-size: 14px;
      }
    }
  }

  &__equipment {
    p {
      font-size     : 16px;
      line-height   : 1.5;
      color         : #313335;
      font-weight   : 500;
      padding-bottom: 10px;
    }

    ul {
      list-style    : none;
      columns       : 3 auto; //3 колонки, ширина авто
      column-gap    : 30px; //расстояние между колонками
      padding-bottom: 20px;

      @media screen and (max-width: @mob) {
        columns: initial;
      }
    }

    li {
      display       : inline-block;
      width         : 100%;
      font-size     : 12px;
      line-height   : 1.3;
      color         : @color-gray;
      padding-bottom: 5px;
    }
  }

}