.offer-car {

  &__wrap {}

  &__row {
    align-items: center;
  }

  &__col--photo {
    width: 61%;

    @media screen and (max-width: @tablet) {
      width: 55%;
    }

    @media screen and (max-width: @ipad) {
      width: 100%;
    }

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }

  &__col--info {
    width: 39%;

    @media screen and (max-width: @tablet) {
      width: 45%;
    }

    @media screen and (max-width: @ipad) {
      width: 100%;
    }

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }

  &__photo-block {
    display        : block;
    text-decoration: none;
    position       : relative;
  }

  &__photo-wrap {
    position     : relative;
    overflow     : hidden;
    width        : 100%;
    border-radius: 10px;
  }

  &__photo {
    object-fit: cover;
    transition: all 0.5s;
    width     : 100%;
    height    : 300px;

    @media screen and (max-width: @mob) {
      height: 250px;
    }
  }

  &__labels {
    position     : absolute;
    width        : 100%;
    top          : 15px;
    padding-left : 15px;
    padding-right: 15px;
  }

  &__labels-list {
    display     : flex;
    flex-wrap   : wrap;
    margin-left : -5px;
    margin-right: -5px;
  }

  &__lablels-item {
    padding-left : 5px;
    padding-right: 5px;
  }

  &__label {
    display      : flex;
    align-items  : center;
    padding      : 5px 12px 4px;
    border-radius: 100px;
    background   : #ffffff;
    font-size    : 14px;
    line-height  : 1.4;
    font-weight  : 500;
    color        : #313335;
  }

  &__info-block {
    width    : 100%;
    max-width: 341px;

    @media screen and (max-width: @tablet) {
      max-width: 100%;
    }

    @media screen and (max-width: @ipad) {
      padding-top: 15px;
    }

    @media screen and (max-width: @mob) {
      max-width  : 100%;
      padding-top: 15px;
    }
  }

  &__main-info-block {
    padding-bottom: 10px;
  }

  &__main-info-wrap {
    display    : flex;
    align-items: flex-end;
  }

  &__name-wrap {
    font-size  : 24px;
    font-weight: 700;
    line-height: 1.3;
  }

  &__name {
    text-decoration: none;
    transition     : all 0.5s;
  }

  &__year {
    padding-left: 25px;
    position    : relative;
    font-size   : 20px;
    font-weight : 500;
    line-height : 1.6;
    color       : #A2A5A8;

    &::before {
      content      : '';
      display      : block;
      position     : absolute;
      width        : 5px;
      height       : 5px;
      border-radius: 50%;
      background   : #A2A5A8;
      top          : 50%;
      transform    : translateY(-50%);
      left         : 10px;
    }
  }

  &__text-block {
    padding-bottom: 15px;
    width         : 100%;

    @media screen and (max-width: @mob) {
      padding-bottom: 10px;
    }
  }

  &__text {
    font-size  : 18px;
    line-height: 1.5;
    color      : @color-gray;

    @media screen and (max-width: @mob) {}
  }

  &__price-block {
    padding-bottom: 15px;
  }

  &__price-wrap {
    padding      : 20px 30px;
    background   : #EFF2F4;
    border-radius: 10px;

    @media screen and (max-width: @mob) {
      padding: 10px 20px;
    }
  }

  &__price {
    display     : flex;
    flex-wrap   : wrap;
    align-items : center;
    margin-left : -7px;
    margin-right: -7px;
  }

  &__price-item {
    padding-left : 7px;
    padding-right: 7px;
  }

  &__new-price {
    font-size  : 28px;
    line-height: 1.3;
    font-weight: 700;
    color      : @color-red;

    @media screen and (max-width: @mob) {
      font-size: 24px;
    }
  }

  &__old-price {
    text-decoration: line-through;
    font-size      : 18px;
    font-weight    : 500;
    line-height    : 1.4;
    color          : @color-gray;

    @media screen and (max-width: @mob) {
      font-size: 16px;
    }
  }

  &__credite {
    display    : flex;
    font-size  : 16px;
    font-weight: 500;
    line-height: 1.5;
    color      : @color-gray;
  }

  &__credite-wrap {
    padding-top: 5px;
  }

  &__credite-value {
    padding-left: 5px;
    color       : @color-red;
  }

  &__btn {
    width: 100%;
  }
}

.offer-car {
  @media screen and (min-width: @min-tablet) {
    &:hover .offer-car__name {
      color: @color-red;
    }

    &:hover .offer-car__photo {
      // filter: grayscale(1);
      // filter: saturate(300%);
    }
  }
}