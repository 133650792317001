@font-face {
    font-family: 'Montserrat';
    src        : url('../fonts/montserrat-v18-latin_cyrillic-regular.woff2') format('woff2'), url('../fonts/montserrat-v18-latin_cyrillic-regular.woff') format('woff'), url('../fonts/montserrat-v18-latin_cyrillic-regular.ttf') format('truetype');
    font-weight: 400;
    font-style : normal;
}

@font-face {
    font-family: 'Montserrat';
    src        : url('../fonts/montserrat-v18-latin_cyrillic-500.woff2') format('woff2'), url('../fonts/montserrat-v18-latin_cyrillic-500.woff') format('woff'), url('../fonts/montserrat-v18-latin_cyrillic-500.ttf') format('truetype');
    font-weight: 500;
    font-style : normal;
}

@font-face {
    font-family: 'Montserrat';
    src        : url('../fonts/montserrat-v18-latin_cyrillic-700.woff2') format('woff2'), url('../fonts/montserrat-v18-latin_cyrillic-700.woff') format('woff'), url('../fonts/montserrat-v18-latin_cyrillic-700.ttf') format('truetype');
    font-weight: 700;
    font-style : normal;
}