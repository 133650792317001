.header {
  background    : #FFFFFF;
  position      : sticky;
  position      : -webkit-sticky;
  top           : 0;
  left          : 0;
  width         : 100%;
  z-index       : 1071;
  padding-top   : 17px;
  padding-bottom: 17px;
  box-shadow    : 0px 5px 20px 0px rgba(44, 41, 51, 0.10);

  &__row {
    align-items    : center;
    justify-content: space-between;
    margin-left    : -20px;
    margin-right   : -20px;

    @media screen and (max-width: @tablet) {
      align-items : center;
      margin-left : 0;
      margin-right: 0;
    }

    @media screen and (max-width: @mob) {
      margin-left : 0;
      margin-right: 0;
    }
  }

  &__col {
    padding-left : 20px;
    padding-right: 20px;

    @media screen and (max-width: @tablet) {
      padding-left : 0;
      padding-right: 0;
    }

    @media screen and (max-width: @mob) {
      padding-left : 0;
      padding-right: 0;
    }
  }

  &__col--menu {
    flex-grow: 1;
  }

  &__col--burger {
    display: none;

    @media screen and (max-width: @tablet) {
      display   : block;
      text-align: right;
    }

    @media screen and (max-width: @mob) {
      // flex-grow: 1;
    }
  }

  &__col--logo {
    z-index : 999;
    position: relative;
  }

  &__col--btn-call {
    display: none;

    @media screen and (max-width: @mob) {
      display      : block;
      text-align   : right;
      flex-grow    : 1;
      padding-right: 30px;
      z-index      : 999;
      position     : relative;
    }
  }

  &__col--city {
    @media screen and (max-width: @mob) {
      display: none;
    }
  }

  &__col--contacts {
    @media screen and (max-width: @mob) {
      display: none;
    }
  }

  &__mob {
    display: none;

    @media screen and (max-width: @mob) {
      display    : block;
      padding-top: 30px;
    }
  }

  &__city {
    display    : flex;
    flex-wrap  : wrap;
    align-items: center;
    font-size  : 0;

    svg {
      width: 20px;
    }

    span {
      max-width   : calc(100% - 20px);
      padding-left: 10px;
      color       : #313335;
      font-size   : 16px;
    }
  }

  &__contact {
    display  : flex;
    flex-wrap: wrap;
  }

  &__contact-icon {
    width    : 24px;
    font-size: 0;
  }

  &__contact-info {
    max-width   : calc(100% - 24px);
    padding-left: 10px;
  }

  &__contact-tel {
    font-size      : 20px;
    font-weight    : 700;
    line-height    : 1.5;
    text-decoration: none;
    display        : block;
  }

  &__contact-note {
    color      : #676767;
    font-size  : 12px;
    line-height: 1.3;
    display    : block;
  }

  &__btn-call {
    display  : inline-block;
    font-size: 0;
  }

  &__mob-city-wrap {
    padding-bottom: 40px;
  }
}

.body--on-open-menu .header {
  &::before {
    @media screen and (max-width: @mob) {
      content       : '';
      display       : block;
      width         : 100%;
      height        : 60px;
      position      : absolute;
      top           : 0;
      left          : 0;
      box-shadow    : 0px 5px 20px 0px rgba(44, 41, 51, 0.10);
      z-index       : 10000;
      pointer-events: none;
    }
  }
}