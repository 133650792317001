.catalog {

  @media screen and (max-width: @tablet) {
    padding-bottom: 70px;
  }

  @media screen and (max-width: @mob) {
    padding-bottom: 50px;
  }

  &__working-block {
    padding-bottom: 30px;

    @media screen and (max-width: @mob) {
      padding-bottom: 20px;
    }
  }

  &__working-row {
    align-items    : center;
    justify-content: space-between;
  }

  &__working-col {
    @media screen and (max-width: @mob) {
      width: auto;
    }
  }

  &__count {
    font-size  : 16px;
    font-weight: 500;
    line-height: 1.5;

    @media screen and (max-width: @mob) {
      font-size: 15px;
    }
  }

  &__wrap {
    padding-bottom: 20px;
  }

  &__item {
    padding-bottom: 10px;
  }
}

.catalog__for-select {
  width    : 200px;
  max-width: 100%;

  @media screen and (max-width: @mob) {
    width: 170px;
  }

  &:before {
    background-color: #ffffff;
  }
}

.catalog__select {
  background : #ffffff;
  color      : #313335;
  font-weight: 400;

  @media screen and (max-width: @mob) {
    height     : 40px;
    font-size  : 14px;
    font-weight: 500;
  }
}

.catalog__select option {
  font-size: 14px;
}