.credite-info {
  padding-top   : 10px;
  padding-bottom: 50px;

  @media screen and (max-width: @mob) {
    padding-top   : 4px;
    padding-bottom: 40px;
  }

  &__preview-picture {
    width        : 100%;
    border-radius: 20px;

    @media screen and (max-width: @mob) {
      border-radius: 10px;
    }
  }

  &__text-wrap {
    padding-top   : 30px;
    padding-bottom: 30px;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 1.5;

    h1,
    h2,
    h3,
    img {
      padding-bottom: 20px;
    }

    p {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    ul {
      list-style    : none;
      padding-bottom: 15px;

      li {
        padding-left  : 20px;
        padding-bottom: 5px;
        position      : relative;

        &::before {
          content      : '';
          display      : block;
          width        : 6px;
          height       : 6px;
          border-radius: 50%;
          background   : @color-black;
          position     : absolute;
          top          : 8px;
          left         : 0;
        }
      }
    }

    ol {
      padding-bottom     : 15px;
      list-style-position: inside;

      li {
        padding-bottom: 5px;
      }
    }
  }
}