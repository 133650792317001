.popup {
  position  : fixed;
  z-index   : 1101;
  top       : 0;
  right     : 0;
  bottom    : 0;
  left      : 0;
  display   : none;
  height    : 100vh;
  margin    : auto;
  padding   : 0;
  background: rgba(17, 17, 17, 0.5);

  @media screen and (max-width: @mob) {
    background: rgba(17, 17, 17, 0.5);
  }

  &__container {
    position         : absolute;
    top              : 50%;
    left             : 50%;
    overflow-y       : auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform    : translate(-50%, -50%);
    transform        : translate(-50%, -50%);

    width     : calc(100% - 80px);
    max-width : @inner;
    max-height: calc(100vh - 80px);

    background : transparent;
    padding    : 0;
    padding-top: 34px;

    @media screen and (min-width: @min-tablet) {
      &::-webkit-scrollbar {
        // width: 0;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius   : 20px;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius   : 20px;
        background-color: @color-gray;
      }
    }


    @media screen and (max-width: @ipad) {
      width     : calc(100% - 60px);
      max-height: calc(100vh - 114px);
    }

    @media screen and (max-width: @mob) {
      width         : calc(100% - 48px);
      max-height    : calc(100vh - 100px);
      display       : flex;
      flex-direction: column;
    }

    &--standard {
      max-width: 370px;
    }

    &--promo {
      max-width: 750px;
    }

  }

  &__inner {
    height       : 100%;
    position     : relative;
    width        : 100%;
    margin       : 0 auto;
    background   : #ffffff;
    border-radius: 10px;

    border    : 0;
    overflow-y: auto;

    @media screen and (max-width: @mob) {
      overflow-y: auto;
    }

  }

  &__close {
    display   : block;
    width     : 24px;
    height    : 24px;
    position  : absolute;
    top       : 0;
    right     : 0;
    cursor    : pointer;
    z-index   : 5;
    transition: all 0.5s;

    @media screen and (max-width: @mob) {
      top  : 0;
      right: 0;
    }

    svg {
      position: absolute;
      left    : 0;
      right   : 0;
      top     : 0;
      bottom  : 0;
      margin  : auto;

      display: block;
      width  : 100%;
      height : 100%;
    }
  }

  //Дополнительно
  &__info-wrap {
    padding: 25px 30px;

    @media screen and (max-width: @mob) {
      padding-left : 20px;
      padding-right: 20px;
    }

    &--promo {
      padding: 0;

      @media screen and (max-width: @mob) {
        padding: 0;
      }
    }
  }

  &__title {
    font-weight   : 700;
    font-size     : 20px;
    line-height   : 1.5;
    font-family   : @font-title;
    padding-bottom: 20px;
  }

  &__subtitle {
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
    padding-bottom: 10px;
  }

  &__for-btn {
    text-align: center;
  }

  &__btn {
    width    : 100%;
    font-size: 18px;
  }
}

.popup-form {
  position: relative;

  &__title {
    padding-bottom: 20px;
    font-size     : 20px;
    line-height   : 1.5;
    font-weight   : 700;
    text-align    : center;
  }

  &__subtitle {
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
    padding-bottom: 10px;
  }

  &__car-info {
    padding-bottom: 10px;
    border-bottom : 1px solid #EFF2F4;
    margin-bottom : 20px;
  }

  &__item-input {
    padding-bottom: 10px;
  }

  &__send-block {
    padding-top: 10px;
  }

  &__btn {
    width    : 100%;
    font-size: 18px;
  }

  &__item-note {
    padding-top: 10px;
  }

  &__note {
    color      : @color-gray;
    font-size  : 12px;
    line-height: 1.3;
  }

  &__messange {
    font-size  : 12px;
    width      : 100%;
    padding-top: 10px;
    line-height: 1.3;
  }
}

.popup__title+.popup__subtitle {
  margin-top: -10px;
}

.popup-contact {
  &__info {
    padding-bottom: 10px;
    border-bottom : 1px solid #EFF2F4;
    margin-bottom : 20px;
  }

  &__btn {
    width    : 100%;
    font-size: 18px;
  }

  &__phone-wrap {
    padding-bottom: 5px;
    font-size     : 24px;
    font-weight   : 700;
    line-height   : 1.3;
  }

  &__phone {
    text-decoration: none;
  }

  &__time {
    font-size  : 14px;
    font-weight: 500;
    line-height: 1.4;
    color      : @color-gray;
  }

  &__subtitle {
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
    padding-bottom: 5px;
  }

  &__address {
    font-size  : 14px;
    font-weight: 500;
    line-height: 1.4;
    color      : @color-red;
  }

  &__btn-wrap {
    padding-top: 20px;
  }
}

.popup-promo {
  &__wrap {
    display    : flex;
    flex-wrap  : wrap;
    align-items: center;
  }

  &__picture-block {
    width: 48%;

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }

  &__info-block {
    width: 52%;

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }

  &__picture {
    width: 100%;
  }

  &__info-wrap {
    padding: 20px 40px;

    @media screen and (max-width: @mob) {
      padding: 20px 20px 40px;
    }
  }

  &__title {
    padding-bottom: 10px;
    font-size     : 32px;
    line-height   : 1.2;
    font-weight   : 700;

    @media screen and (max-width: @mob) {
      font-size: 24px;
    }
  }

  &__text {
    font-size  : 16px;
    line-height: 1.5;
    font-weight: 500;
  }

  &__btn-wrap {
    padding-top: 30px;
  }

  &__btn {
    width: 100%;
  }
}