.row {
  display     : flex;
  flex-wrap   : wrap;
  margin-left : -15px;
  margin-right: -15px;

  @media screen and (max-width: @mob) {
    margin-left : -5px;
    margin-right: -5px;
  }

  &--center-vertical {
    align-content: center;
    align-items  : center;
  }
}

.col {
  padding-left : 15px;
  padding-right: 15px;

  @media screen and (max-width: @mob) {
    padding-left : 5px;
    padding-right: 5px;
  }
}

.col-1 {
  width: 8.33%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-2 {
  width: 16.66%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-3 {
  width: 25%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-4 {
  width: 33.33%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-5 {
  width: 41.66%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-6 {
  width: 50%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-7 {
  width: 58.33%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-8 {
  width: 66.66%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-9 {
  width: 75%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-10 {
  width: 83.33%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-11 {
  width: 91.66%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-12 {
  width: 100%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

.col-five {
  width: 20%;

  @media screen and (max-width: @mob) {
    width: 100%;
  }
}

@media screen and (max-width: @tablet) {
  .col-lg-1 {
    width: 8.33%;
  }

  .col-lg-2 {
    width: 16.66%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-4 {
    width: 33.33%;
  }

  .col-lg-5 {
    width: 41.66%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-7 {
    width: 58.33%;
  }

  .col-lg-8 {
    width: 66.66%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-10 {
    width: 83.33%;
  }

  .col-lg-11 {
    width: 91.66%;
  }

  .col-lg-12 {
    width: 100%;
  }
}

@media screen and (max-width: @ipad) {
  .col-md-1 {
    width: 8.33%;
  }

  .col-md-2 {
    width: 16.66%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-4 {
    width: 33.33%;
  }

  .col-md-5 {
    width: 41.66%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-7 {
    width: 58.33%;
  }

  .col-md-8 {
    width: 66.66%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-10 {
    width: 83.33%;
  }

  .col-md-11 {
    width: 91.66%;
  }

  .col-md-12 {
    width: 100%;
  }
}

@media screen and (max-width: @mob) {
  .col-sm-1 {
    width: 8.33%;
  }

  .col-sm-2 {
    width: 16.66%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-4 {
    width: 33.33%;
  }

  .col-sm-5 {
    width: 41.66%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-7 {
    width: 58.33%;
  }

  .col-sm-8 {
    width: 66.66%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-10 {
    width: 83.33%;
  }

  .col-sm-11 {
    width: 91.66%;
  }

  .col-sm-12 {
    width: 100%;
  }
}

.offset-1 { margin-left: 8.33%; }
.offset-2 { margin-left: 16.66%; }
.offset-3 { margin-left: 25%; }
.offset-4 { margin-left: 33.33%; }
.offset-5 { margin-left: 41.66%; }
.offset-6 { margin-left: 50%; }
.offset-lg-0 {
    @media screen and (max-width: @tablet) {
        margin-left: 0;
    }
}
.offset-md-0 {
    @media screen and (max-width: @ipad) {
        margin-left: 0;
    }
}
.offset-sm-0 {
    @media screen and (max-width: @mob) {
        margin-left: 0;
    }
}