.advantages {
  padding-bottom: 25px;

  @media screen and (max-width: @mob) {
    padding-bottom: 30px;
  }

  &__title {
    padding-bottom: 30px;
    font-size     : 24px;
    line-height   : 1.3;
  }

  &__row {
    margin-left : -12px;
    margin-right: -12px;
  }

  &__col {
    padding-left  : 12px;
    padding-right : 12px;
    padding-bottom: 25px;

    @media screen and (max-width: @ipad) {
      padding-bottom: 15px;
    }

    @media screen and (max-width: @mob) {
      padding-bottom: 10px;
    }
  }

  &__item {
    border       : 1px solid #E0E1E7;
    border-radius: 10px;
    background   : #ffffff;
    padding      : 30px;
    height       : 100%;

    @media screen and (max-width: @mob) {
      padding: 20px 30px;
    }
  }

  &__item-wrap {
    @media screen and (max-width: @tablet) {
      display  : flex;
      flex-wrap: wrap;
    }
  }

  &__item-icon {
    @media screen and (max-width: @tablet) {
      width: 48px;
    }
  }

  &__item-info {
    padding-top: 20px;

    @media screen and (max-width: @tablet) {
      padding-top : 0;
      width       : calc(100% - 48px);
      padding-left: 20px;
    }
  }

  &__item-name {
    padding-bottom: 10px;
    font-size     : 16px;
    line-height   : 1.5;
    font-weight   : 700;
    width         : 100%;
    max-width     : 120px;

    @media screen and (max-width: @tablet) {
      max-width: 100%;
    }
  }

  &__item-text {
    font-size  : 14px;
    line-height: 1.4;
    color      : @color-gray;
  }
}