.car-photo {
  position: relative;

  @media screen and (max-width: @mob) {
    margin-left : -15px;
    margin-right: -15px;
  }

  &__item-thumbs {
    span {
      display: none;
    }
  }

  &__item-thumbs--btn {
    position: relative;

    span {
      display        : flex;
      width          : calc(100% - 10px);
      height         : calc(100% - 10px);
      z-index        : 5;
      background     : rgba(0, 0, 0, 0.5);
      position       : absolute;
      top            : 0;
      left           : 5px;
      border-radius  : 10px;
      align-items    : center;
      justify-content: center;
      font-size      : 16px;
      font-weight    : 500;
      color          : #ffffff;
      cursor         : pointer;
    }
  }

  //***** Photogallery's *****//
  &__photogallery-wrap {
    position: relative;
  }

  &__photo-wrap {
    width   : auto;
    width   : 100%;
    position: relative;

    a {
      display: block;
      height : 500px;
      width  : 100%;

      @media screen and (max-width: @mob) {
        height: 300px;
      }
    }
  }

  &__photo {
    // position  : absolute;
    // top       : 0;
    // left      : 0;
    width        : 100%;
    height       : 100%;
    object-fit   : cover;
    border-radius: 8px;

    @media screen and (max-width: @mob) {
      border-radius: 0;
    }
  }

  &__for-thumbs {
    overflow   : hidden;
    padding-top: 20px;

    @media screen and (max-width: @mob) {
      display: none;
    }
  }

  &__thumbs {

    .slick-track:before,
    .slick-track:after {
      display: none;
    }

    .slick-track {
      display  : flex;
      flex-wrap: wrap;
      width    : 100% !important;
    }

    .slick-list {
      margin-left : -5px;
      margin-right: -5px;
    }

    .car-photo__item-thumbs {
      padding-left : 5px;
      padding-right: 5px;
    }

  }

  &__thumbs-photo-wrap {
    width        : 145px;
    height       : 90px;
    position     : relative;
    cursor       : pointer;
    background   : #FAFAFA;
    margin-bottom: 10px;
  }

  &__thumbs-photo {
    position     : absolute;
    top          : 0;
    left         : 0;
    width        : 100%;
    height       : 100%;
    object-fit   : cover;
    border-radius: 10px;
  }
}

.car-photo__thumbs .car-photo__item-thumbs.slick-current .car-photo__thumbs-photo {
  border: 2px solid @color-red;
}

// Slider Nav
.car-photo {

  &__nav-btn {
    pointer-events : auto;
    display        : inline-flex;
    align-content  : center;
    align-items    : center;
    justify-content: center;
    width          : 50px;
    height         : 50px;
    transition     : all 0.5s;
    border-radius  : 50%;
    background     : #ffffff;
    position       : absolute;
    top            : 50%;
    transform      : translateY(-50%);

    @media screen and (max-width: @mob) {
      width : 40px;
      height: 40px;
    }

    svg {
      path {
        transition: all 0.5s;
        stroke    : @color-gray;
      }
    }

    &--before {
      left: 30px;

      @media screen and (max-width: @mob) {
        left: 15px;
      }
    }

    &--after {
      right    : 30px;
      transform: translateY(-50%) rotate(180deg);

      @media screen and (max-width: @mob) {
        right: 15px;
      }
    }

    @media screen and (min-width: @min-tablet) {
      &:hover {
        svg path {
          stroke: @color-red;
        }
      }
    }
  }
}

.car-photo__dots {
  position: absolute;
  bottom  : 15px;
  left    : 25px;
  right   : 25px;
  margin  : auto;
  width   : calc(100% - 50px);

  @media screen and (max-width: @ipad) {
    width: calc(100% - 20px);
    left : 10px;
    right: 10px;
  }
}

.car-photo__dots .slick-dots {
  display       : flex;
  flex-wrap     : wrap;
  align-content : center;
  align-items   : center;
  list-style    : none;
  pointer-events: auto;
  margin-left   : -5px;
  margin-right  : -5px;

  @media screen and (max-width: @mob) {
    margin-left : -2px;
    margin-right: -2px;
  }

  li {
    line-height: 0;
    font-size  : 0;
    margin     : 0;
    padding    : 0 5px;
    flex-grow  : 1;

    @media screen and (max-width: @mob) {
      padding: 0 2px;
    }
  }

  button {
    font-size    : 0;
    width        : 100%;
    height       : 3px;
    border-radius: 2px;
    background   : #FFFFFF;
    position     : relative;
    transition   : all 0.2s;
    opacity      : 0.4;
    border-radius: 10px;

    @media screen and (min-width: @min-tablet) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .slick-active button {
    opacity   : 1;
    background: @color-red;
  }
}