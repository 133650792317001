.slider-promo-cars {
  position: relative;
}

// Slider Nav
.slider-promo-cars {

  &__nav-btn {
    pointer-events : auto;
    display        : inline-flex;
    align-content  : center;
    align-items    : center;
    justify-content: center;
    width          : 50px;
    height         : 50px;
    transition     : all 0.5s;
    border-radius  : 50%;
    border         : 1px solid #E0E1E7;
    position       : absolute;
    top            : 50%;
    transform      : translateY(-50%);

    @media screen and (max-width: @mob) {
      top      : auto;
      transform: none;
      bottom   : -70px;
      width    : 40px;
      height   : 40px;
    }

    svg {
      path {
        transition: all 0.5s;
      }
    }

    &--before {
      left: -89px;

      @media screen and (max-width: @tablet) {
        left: -60px;
      }

      @media screen and (max-width: @mob) {
        left: calc(50% - 50px);
      }
    }

    &--after {
      right    : -89px;
      transform: translateY(-50%) rotate(180deg);

      @media screen and (max-width: @tablet) {
        right: -60px;
      }

      @media screen and (max-width: @mob) {
        right    : calc(50% - 50px);
        transform: rotate(180deg)
      }
    }

    @media screen and (min-width: @min-tablet) {
      &:hover {
        svg path {
          stroke: @color-red;
        }
      }
    }
  }
}

.slider-promo-cars__dots {
  position: absolute;
  bottom  : 62px;
  left    : 10px;
  right   : 10px;
  margin  : auto;

  @media screen and (max-width: @ipad) {
    bottom: auto;
    top   : 260px;
    width: calc(100% - 20px);
  }

  @media screen and (max-width: @mob) {
    bottom: auto;
    top   : 234px;
    width: calc(100% - 20px);
  }
}

.slider-promo-cars__dots .slick-dots {
  display       : flex;
  flex-wrap     : wrap;
  align-content : center;
  align-items   : center;
  list-style    : none;
  pointer-events: auto;
  margin-left   : -3px;
  margin-right  : -3px;

  @media screen and (max-width: @ipad) {
    justify-content: center;
  }

  @media screen and (max-width: @mob) {
    margin-left : -3px;
    margin-right: -3px;
  }

  li {
    line-height: 0;
    font-size  : 0;
    margin     : 0;
    padding    : 0 3px;

    @media screen and (max-width: @mob) {
      padding  : 0 3px;
      flex-grow: 1;
    }
  }

  button {
    font-size    : 0;
    width        : 66px;
    height       : 4px;
    border-radius: 2px;
    background   : #FFFFFF;
    position     : relative;
    transition   : all 0.2s;
    opacity      : 0.4;
    border-radius: 20px;

    @media screen and (max-width: @mob) {
      width: 100%;
    }

    @media screen and (min-width: @min-tablet) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .slick-active button {
    opacity: 1;
  }
}