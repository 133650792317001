.catalog-filter {
  padding      : 20px 20px 0;
  background   : #ffffff;
  border-radius: 20px;
  box-shadow   : 0px 10px 30px 0px rgba(14, 25, 35, 0.10);

  @media screen and (max-width: @mob) {
    padding: 25px 20px 10px;
  }

  &__item {
    padding-bottom: 20px;

    @media screen and (max-width: @mob) {
      padding-bottom: 15px;
    }
  }

  &__row {
    margin-left : -7px;
    margin-right: -7px;
    align-items : center;
  }

  &__col {
    padding-left : 7px;
    padding-right: 7px;
    transition   : all 0.5s;

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }

  &__col--btn-submit {
    margin-left: auto;
  }

  &__col--btn-all,
  &__col--btn-reset {
    @media screen and (max-width: @mob) {
      order     : 3;
      text-align: center;
    }
  }

  &__btn {
    width      : 100%;
    font-size  : 16px;
    font-weight: 700;

    @media screen and (max-width: @mob) {
      font-weight: 500;
      font-size  : 15px;
    }
  }

  &__input-price-wrap {
    position: relative;
    display : flex;
  }

  &__input-price-item--from {
    width: 55%;

    .form__input {
      border-radius: 10px 0 0 10px;
      border-right : 0;
    }
  }

  &__input-price-item--to {
    width: 45%;

    .form__input {
      border-radius: 0 10px 10px 0;
    }
  }

  &__range-price {
    position: absolute;
    bottom  : 0;
    left    : 15px;
    right   : 15px;
  }

  &__btn-more {
    display    : inline-flex;
    flex-wrap  : wrap;
    align-items: center;
    font-size  : 0;

    @media screen and (max-width: @mob) {
      white-space: nowrap;
    }

    svg {
      width     : 24px;
      transition: all 0.5s;
    }

    span {
      color        : @color-red;
      font-size    : 16px;
      line-height  : 1.4;
      padding-right: 5px;
      max-width    : calc(100% - 24px);
      padding-top  : 2px;
      white-space  : nowrap;
    }

    &.js-filter-btn-all--open {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  &__btn-reset {
    display    : inline-flex;
    flex-wrap  : wrap;
    align-items: center;
    font-size  : 0;
    margin-left: 10px;

    @media screen and (max-width: @mob) {
      white-space: nowrap;
      margin-left: 0;
    }

    svg {
      width     : 20px;
      transition: all 0.5s;
    }

    span {
      color       : #919395;
      font-size   : 16px;
      line-height : 1.4;
      padding-left: 5px;
      max-width   : calc(100% - 20px);
      padding-top : 2px;
    }
  }

}

.js-filter-mob-item-toggle {
  @media screen and (max-width: @mob) {
    display: none;
  }
}

.catalog-filter.js-filter-block--open {

  .js-filter-mob-item-toggle {
    @media screen and (max-width: @mob) {
      display: block;
    }
  }

  .catalog-filter__col--btn-submit {
    max-width  : 100%;
    margin-left: auto;

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }
}