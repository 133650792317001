.catalog-car {
  padding      : 15px;
  border-radius: 10px;
  background   : #ffffff;
  box-shadow   : 0px 5px 20px 0px rgba(12, 19, 26, 0.05);

  @media screen and (max-width: @mob) {
    margin-left : -15px;
    margin-right: -15px;
  }

  &__wrap {}

  &__row {
    margin-left : 0;
    margin-right: 0;

    @media screen and (max-width: @mob) {
      margin-left : 0;
      margin-right: 0;
    }
  }

  &__col {
    padding-left : 0;
    padding-right: 0;

    @media screen and (max-width: @mob) {
      padding-left : 0;
      padding-right: 0;
    }
  }

  &__col--photo {
    width: 240px;

    @media screen and (max-width: @mob) {
      width: 100%;
    }
  }

  &__col--info {
    width        : calc(100% - 240px - 40px);
    padding-left : 14px;
    padding-right: 20px;

    @media screen and (max-width: @mob) {
      width        : 100%;
      padding-left : 0;
      padding-right: 0;
      order        : -1;
    }
  }

  &__col--mob-info {
    display: none;

    @media screen and (max-width: @mob) {
      display: block;
      width  : 100%;
    }
  }

  &__col--btns {
    width: 40px;

    @media screen and (max-width: @mob) {
      display: none;
      width  : 100%;
    }
  }

  &__photo-block {
    display        : block;
    text-decoration: none;
    position       : relative;
  }

  &__photo-wrap {
    position     : relative;
    overflow     : hidden;
    width        : 100%;
    border-radius: 10px;

    @media screen and (max-width: @mob) {
      border-radius: 0;
    }
  }

  &__photo {
    object-fit: cover;
    transition: all 0.5s;
    width     : 100%;
    height    : 180px;

    @media screen and (max-width: @mob) {
      width        : 243px;
      height       : 200px;
      border-radius: 10px;
    }
  }

  &__labels {
    position     : absolute;
    width        : 100%;
    top          : 10px;
    padding-left : 10px;
    padding-right: 10px;
  }

  &__labels-list {
    display     : flex;
    flex-wrap   : wrap;
    margin-left : -5px;
    margin-right: -5px;
  }

  &__lablels-item {
    padding-left : 5px;
    padding-right: 5px;
  }

  &__label {
    display      : flex;
    align-items  : center;
    padding      : 5px 12px 4px;
    border-radius: 100px;
    background   : #ffffff;
    font-size    : 12px;
    line-height  : 1.3;
    font-weight  : 500;
    color        : #313335;
  }

  &__main-info {
    padding-bottom: 15px;
  }

  &__main-info-row {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
  }

  &__name-wrap {
    padding-bottom: 5px;

    @media screen and (max-width: @mob) {
      padding-bottom: 0;
    }
  }

  &__name {
    display        : inline-block;
    font-size      : 16px;
    font-weight    : 700;
    line-height    : 1.5;
    color          : @color-red;
    text-decoration: none;
    transition     : all 0.5s;

    @media screen and (max-width: @mob) {
      color: @color-black;
    }

    @media screen and (min-width: @min-tablet) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__price {
    display     : flex;
    flex-wrap   : wrap;
    align-items : center;
    margin-left : -5px;
    margin-right: -5px;
  }

  &__price-item {
    padding-left : 5px;
    padding-right: 5px;
  }

  &__new-price {
    font-size  : 18px;
    line-height: 1.5;
    font-weight: 700;
  }

  &__old-price {
    text-decoration: line-through;
    font-size      : 12px;
    font-weight    : 500;
    line-height    : 1.5;
    color          : @color-gray;
  }

  &__credite-wrap {
    border       : 1px solid #E0E1E7;
    border-radius: 5px;
    background   : #ffffff;
    padding      : 10px 15px 8px;
  }

  &__credite-item {
    font-size  : 14px;
    line-height: 1.4;
    color      : #515456;

    @media screen and (max-width: @mob) {
      font-size  : 12px;
      line-height: 1.3;
    }
  }

  &__credite-value {
    color: @color-red;
  }

  &__text-wrap {
    @media screen and (max-width: @mob) {
      display: none;
    }
  }

  &__text-item {
    padding-bottom: 15px;
    font-size     : 14px;
    line-height   : 1.4;
    color         : @color-gray;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__mob-text-wrap {
    @media screen and (max-width: @mob) {
      padding-top: 15px;
    }
  }

  &__mob-text {
    @media screen and (max-width: @mob) {
      font-size  : 14px;
      line-height: 1.4;
      color      : @color-gray;
    }
  }

  &__btns-block {
    display: block;
  }

  &__btns-item {
    padding-bottom: 10px;
  }

  &__btn {
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : 40px;
    height         : 40px;
    border-radius  : 10px;
    font-size      : 0;
    transition     : all 0.5s;

    &--red {
      background: @color-red;

      @media screen and (min-width: @min-tablet) {
        &:hover {
          background: #c1082a;
        }
      }
    }

    &--green {
      background: #0EA348;

      @media screen and (min-width: @min-tablet) {
        &:hover {
          background: #0b7f38;
        }
      }
    }
  }

}