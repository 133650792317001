.promo-car {
  @media screen and (max-width: @mob) {
    margin-left  : -15px;
    margin-right : -15px;
    width        : calc(100% + 30px);
    background   : #ffffff;
    border-radius: 10px;
    box-shadow   : 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
    padding      : 15px;
  }

  &__wrap {
    display       : flex;
    flex-direction: column;
  }

  &__photo-block {
    display        : block;
    text-decoration: none;
    position       : relative;

    @media screen and (max-width: @mob) {
      order: 3;
    }
  }

  &__photo-wrap {
    position      : relative;
    overflow      : hidden;
    width         : 100%;
    padding-bottom: 67.6%;
    border-radius : 10px;

    &:before {
      content       : '';
      display       : block;
      width         : 100%;
      height        : 50px;
      position      : absolute;
      bottom        : 0;
      left          : 0;
      background    : linear-gradient(0deg, #0D121C 1.67%, rgba(13, 18, 28, 0.00) 100%);
      opacity       : 0.1;
      pointer-events: none;
      z-index       : 5;
    }
  }

  &__photo {
    width     : 100%;
    height    : 100%;
    position  : absolute;
    top       : 0;
    left      : 0;
    object-fit: cover;
    transition: all 0.5s;
  }

  &__labels {
    position     : absolute;
    width        : 100%;
    top          : 15px;
    padding-left : 15px;
    padding-right: 15px;

    @media screen and (max-width: @mob) {
      top          : 10px;
      padding-left : 10px;
      padding-right: 10px;
    }
  }

  &__labels-list {
    display     : flex;
    flex-wrap   : wrap;
    margin-left : -5px;
    margin-right: -5px;
  }

  &__lablels-item {
    padding-left : 5px;
    padding-right: 5px;
  }

  &__label {
    display      : flex;
    align-items  : center;
    padding      : 5px 12px 4px;
    border-radius: 100px;
    background   : #ffffff;
    font-size    : 14px;
    line-height  : 1.4;
    font-weight  : 500;
    color        : #313335;

    @media screen and (max-width: @mob) {
      font-size  : 12px;
      line-height: 1.3;
    }
  }

  &__main-info-block {
    padding-top   : 15px;
    padding-bottom: 10px;

    @media screen and (max-width: @mob) {
      order         : 1;
      padding-top   : 0;
      padding-bottom: 0;
    }
  }

  &__main-info-wrap {
    display    : flex;
    align-items: flex-end;

    @media screen and (max-width: @mob) {
      // align-items: center;
    }
  }

  &__name-wrap {
    font-size  : 20px;
    font-weight: 700;
    line-height: 1.5;

    @media screen and (max-width: @mob) {
      font-size: 16px;
    }
  }

  &__name {
    text-decoration: none;
    transition     : all 0.5s;
  }

  &__year {
    padding-left: 25px;
    position    : relative;
    font-size   : 18px;
    font-weight : 500;
    line-height : 1.6;
    color       : #A2A5A8;

    @media screen and (max-width: @mob) {
      padding-left: 8px;
      font-size   : 14px;
    }

    &::before {
      content      : '';
      display      : block;
      position     : absolute;
      width        : 5px;
      height       : 5px;
      border-radius: 50%;
      background   : #A2A5A8;
      top          : 50%;
      transform    : translateY(-50%);
      left         : 10px;

      @media screen and (max-width: @mob) {
        display: none;
      }
    }
  }

  &__text-block {
    padding-bottom: 15px;
    width         : 100%;
    max-width     : 350px;

    @media screen and (max-width: @mob) {
      order         : 4;
      padding-top   : 15px;
      padding-bottom: 0;
      max-width     : 100%;
    }
  }

  &__text {
    font-size  : 16px;
    line-height: 1.5;
    color      : @color-gray;

    @media screen and (max-width: @mob) {
      font-size  : 14px;
      line-height: 1.4;
    }
  }

  &__price-block {
    @media screen and (max-width: @mob) {
      order: 2;
    }
  }

  &__price-wrap {
    padding      : 15px 20px;
    background   : #EFF2F4;
    border-radius: 10px;

    @media screen and (max-width: @mob) {
      padding      : 0;
      background   : transparent;
      border-radius: 0;
    }
  }

  &__price {
    display     : flex;
    align-items : flex-end;
    flex-wrap   : wrap;
    margin-left : -7px;
    margin-right: -7px;

    @media screen and (max-width: @mob) {
      margin-left : -5px;
      margin-right: -5px;
      align-items : center;
    }
  }

  &__price-item {
    padding-left : 7px;
    padding-right: 7px;

    @media screen and (max-width: @mob) {
      padding-left : 5px;
      padding-right: 5px;
    }
  }

  &__new-price {
    font-size  : 24px;
    line-height: 1.3;
    font-weight: 700;

    @media screen and (max-width: @mob) {
      font-size  : 18px;
      line-height: 1.5;
    }
  }

  &__old-price {
    text-decoration: line-through;
    font-size      : 16px;
    font-weight    : 500;
    line-height    : 1.4;
    color          : @color-gray;

    @media screen and (max-width: @mob) {
      font-size  : 12px;
      line-height: 1.5;
    }
  }

  &__credite {
    display    : flex;
    font-size  : 14px;
    font-weight: 500;
    line-height: 1.4;
    color      : @color-gray;

    @media screen and (max-width: @mob) {
      font-size  : 12px;
      font-weight: 400;
      line-height: 1.3;
    }
  }

  &__credite-wrap {
    padding-top: 5px;

    @media screen and (max-width: @mob) {
      padding-top   : 0;
      padding-bottom: 15px;
    }
  }

  &__credite-value {
    padding-left: 5px;
    color       : @color-red;
  }
}

.promo-car {
  @media screen and (min-width: @min-tablet) {
    &:hover .promo-car__name {
      color: @color-red;
    }

    &:hover .promo-car__photo {
      // filter: grayscale(1);
      filter: saturate(300%);
    }
  }
}