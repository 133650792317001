.action-adv {
  display        : block;
  text-decoration: none;

  &__picture-wrap {
    position      : relative;
    width         : 100%;
    height        : 0;
    padding-bottom: 71.2%;
    border-radius : 20px 20px 10px 10px;
    overflow      : hidden;

    @media screen and (max-width: @mob) {
      padding-bottom: 58%;
      border-radius : 10px;
    }
  }

  &__picture {
    position  : absolute;
    top       : 0;
    left      : 0;
    width     : 100%;
    height    : 100%;
    object-fit: cover;
  }

  &__text-wrap {
    padding-top: 20px;
  }

  &__title {
    padding-bottom: 13px;
    font-size     : 20px;
    line-height   : 1.5;
    font-weight   : 700;

    @media screen and (max-width: @mob) {
      font-size     : 18px;
      padding-bottom: 10px;
    }
  }
}