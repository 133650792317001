.burger-menu {

  @media screen and (max-width: @tablet) {
    pointer-events: none;
    display       : none;
    position      : absolute;
    right         : 0;
    top           : 0;
    width         : 340px;
    max-width     : 100%;
    max-height    : 100vh;

    z-index   : 600;
    overflow-y: auto;
  }

  @media screen and (max-width: @mob) {
    width : 100%;
    height: 100vh;
  }

  &__wrap {

    @media screen and (max-width: @tablet) {
      pointer-events: auto;
      background    : #ffffff;
      padding       : 20px 24px 30px;
      max-height    : 100%;
      overflow-y    : auto;
    }

    @media screen and (max-width: @mob) {
      min-height    : 100%;
      border-radius : 0;
      padding       : 20px 24px 80px; //здесь будет срабатывать padding-bottom, тк это внутри основного блока, у которого фикс. высота!
      margin-top    : 0;
      pointer-events: auto;
    }
  }
}