.pagination {
  &__list {
    list-style     : none;
    display        : flex;
    justify-content: center;
    align-content  : center;
    align-items    : center;
    margin-left    : -5px;
    margin-right   : -5px;
  }

  &__item {
    padding-left : 5px;
    padding-right: 5px;

    &--prev {
      padding-right: 15px;
    }

    &--next {
      padding-left: 15px;
    }
  }

  &__link {
    width          : 36px;
    height         : 36px;
    display        : flex;
    align-content  : center;
    align-items    : center;
    justify-content: center;
    background     : transparent;
    transition     : all 0.5s;
    font-size      : 16px;
    font-weight    : 500;
    text-decoration: none;
    border-radius  : 8px;
    background     : #ffffff;
    color          : #313335;
    padding-top    : 2px;

    svg path {
      transition: all 0.5s;
    }

    @media screen and (min-width: @min-tablet) {
      &:hover {
        background: @color-gray;
        color     : #ffffff;

        svg path {
          stroke: #ffffff;
        }
      }
    }
  }

  &__link--active {
    background: @color-red;
    color     : #ffffff;
  }

  &__link--page-prev,
  &__link--page-next {
    border: 1px solid #E0E1E7;

    @media screen and (min-width: @min-tablet) {
      &:hover {}
    }
  }

}

span.pagination__link {
  opacity       : 0.5;
  pointer-events: none;

  @media screen and (min-width: @min-tablet) {
    &:hover {}
  }
}