.privilege {
  width          : 100%;
  height         : 220px;
  border-radius  : 20px;
  background     : #ffffff;
  padding        : 30px;
  position       : relative;
  display        : block;
  text-decoration: none;

  &__info-wrap {
    position: relative;
    z-index : 5;
  }

  &__name {
    font-size     : 24px;
    font-weight   : 700;
    line-height   : 1.3;
    padding-bottom: 10px;
    width         : 100%;
    max-width     : 230px;
  }

  &__picture-wrap {
    position : absolute;
    right    : 0;
    bottom   : 0;
    width    : 60%;
    max-width: 100%;
  }
}

.privilege--more-cars .privilege__picture-wrap {
  bottom: 7px;
  width : 209px;
}

.privilege--car-exchange .privilege__picture-wrap {
  bottom: 10px;
  width : 174px;
}

.privilege--credite .privilege__picture-wrap {
  right : 7px;
  bottom: 5px;
  width : 163px;

  @media screen and (max-width: @tablet) {
    right : 0;
    bottom: 0;
  }
}