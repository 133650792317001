.breadcrumbs {
  padding-top   : 20px;
  padding-bottom: 20px;

  @media screen and (max-width: @mob) {
    padding-top   : 16px;
    padding-bottom: 16px;
  }

  &--not-mob {
    @media screen and (max-width: @mob) {
      display: none;
    }
  }
}

.breadcrumbs__list {
  display    : flex;
  flex-wrap  : wrap;
  list-style : none;
  font-size  : 16px;
  line-height: 1.5;
  color      : @color-gray;
  font-weight: 400;

  @media screen and (max-width: @mob) {
    font-size: 14px;
  }
}

.breadcrumbs__item {
  &:after {
    content     : ' /  ';
    margin-right: 10px;
    margin-left : 10px;

    @media screen and (max-width: @mob) {
      margin-left : 5px;
      margin-right: 8px;
    }
  }

  &:last-child:after {
    display: none;
  }
}

.breadcrumbs__link {
  text-decoration: none;

  @media screen and (min-width: @min-tablet) {
    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumbs__span {
  font-weight: 400;
}