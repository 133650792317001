.promo-privileges {
  background    : #F2F5F7;
  padding-bottom: 40px;

  @media screen and (max-width: @mob) {
    padding-bottom: 20px;
  }

  &__row {
    margin-left    : -10px;
    margin-right   : -10px;
    justify-content: center;
  }

  &__col {
    padding-left : 10px;
    padding-right: 10px;
  }

  &__item-wrap {
    padding-bottom: 20px;
  }
}