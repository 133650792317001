.fancybox-thumbs__list a::before {
  border-color: @color-red;
  border-width: 4px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 50px;

  @media screen and (max-width: @mob) {
    left: 0;
  }
}

.fancybox-navigation .fancybox-button--arrow_right {
  right: 50px;

  @media screen and (max-width: @mob) {
    right: 0;
  }
}

.fancybox-thumbs {
  background: rgba(30, 30, 30, 0.6);
}