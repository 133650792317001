// Стили для выпадаек form__select2-standard + &--theme-select2-standard
.form__select2-standard {
  position: relative;
}

.form__select2-standard:before {
  content: none;
}

.form__select2-standard .select2-container {
  background: transparent;
}

//Само поле вывода
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single {
  background     : transparent;
  position       : relative;
  height         : 54px;
  padding        : 5px 24px 5px 24px;
  border         : 1px solid @color-gray;
  border-radius  : 0;
  display        : flex;
  align-content  : center;
  align-items    : center;
  background     : transparent;
  display        : flex;
  justify-content: center;

  @media screen and (max-width: @mob) {
    height: 48px;
  }
}

//placeholder
.form__select2-standard .select2-container--theme-select2-standard .select2-selection__placeholder {
  font-family   : @font;
  color         : @color-gray;
  font-weight   : 400;
  font-size     : 18px;
  text-transform: uppercase;
  text-align    : center;

  @media screen and (max-width: @mob) {
    font-size: 16px;
  }
}

//выбранный пункт
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__rendered {
  font-family   : @font;
  color         : @color-gray;
  font-size     : 18px;
  font-weight   : 400;
  text-transform: uppercase;
  text-align    : center;

  @media screen and (max-width: @mob) {
    font-size: 16px;
  }
}

//стрелочка в селекте
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__arrow {
  position: static;
}

.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__arrow b {
  position           : absolute;
  border             : none;
  width              : 18px;
  height             : 10px;
  margin             : auto;
  content            : '';
  pointer-events     : none;
  background-image   : url(../images/icons/icon-more.svg);
  background-position: right center;
  background-repeat  : no-repeat;
  transition         : transform .5s ease-in-out;
  top                : 0;
  bottom             : 0;
  right              : 24px;

  @media screen and (max-width: @ipad) {}
}

//стрелочка, когда открыт список выбора
.form__select2-standard .select2-container--theme-select2-standard.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotateX(180deg);
}

//Блок с выбором
.select2-container--theme-select2-standard .select2-results__options {
  padding      : 0;
  position     : relative;
  background   : @color-black;
  border-radius: 0;
  border       : 1px solid @color-gray;
  border-top   : 0;
  max-height   : 300px;
  overflow-y   : auto;

  @media screen and (max-width: @mob) {
    max-height: 60vh;
  }

  @media screen and (min-width: @min-tablet) {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #E0E0E0;
    }

    &::-webkit-scrollbar-thumb {
      background: #9D9CAF;
    }
  }
}

//Элемент выбора в списке
.select2-container--theme-select2-standard .select2-results__option {
  font-family   : @font;
  color         : @color-gray;
  font-size     : 18px;
  padding       : 15px 24px;
  text-transform: uppercase;
  text-align    : center;

  @media screen and (max-width: @mob) {
    font-size: 16px;
  }
}

//Элемент выбора в списке при наведении
.select2-container--theme-select2-standard .select2-results__option--highlighted:hover {
  color     : @color-gray;
  background: rgba(217, 217, 216, 0.5);
}

//Выбранный элемент в списке
.select2-container--theme-select2-standard .select2-results__option[aria-selected=true] {
  color: @color-gray;
}

//***дополнительно***//

// само поле вывода, когда выбран пункт (доп. класс чз js)
.select2--ok.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single {
  // background: skyblue;
}

.select2--ok.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__arrow b {}

//когда ошибка (доп. класс чз js)
.form__select2-standard .js-form-input-error+.select2-container--theme-select2-standard .select2-selection--single {
  border-color: @color-red  !important;
}

.form__select2-standard .js-form-input-error+.select2-container--theme-select2-standard .select2-selection__placeholder {
  color: @color-red  !important;
}

//само поле вывода, когда открыт выбор пунктов
.form__select2-standard .select2-container--open.select2-container--theme-select2-standard .select2-selection--single {
  border-bottom: 0;
}

//Поиск
.select2-container--theme-select2-standard .select2-search--dropdown {
  // background   : #ffffff;
  // padding      : 0 17px 5px;
  // border       : 1px solid #9D9CAF;
  // border-bottom: 0;
  // border-top   : 0;
  // position     : relative;

  &:before {
    // content         : '';
    // display         : block;
    // position        : absolute;
    // top             : 0;
    // bottom          : 0;
    // right           : 27px;
    // margin          : auto;
    // background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.333 15c1.479 0 2.915-.496 4.08-1.407l3.664 3.663 1.178-1.178-3.663-3.663a6.626 6.626 0 001.407-4.082 6.674 6.674 0 00-6.666-6.667 6.674 6.674 0 00-6.667 6.667A6.674 6.674 0 008.333 15zm0-11.667c2.757 0 5 2.243 5 5 0 2.758-2.243 5-5 5-2.758 0-5-2.242-5-5 0-2.757 2.242-5 5-5z' fill='%23E0E0E0'%3E%3C/path%3E%3C/svg%3E");
    // width           : 20px;
    // height          : 20px;
    // transform       : translateY(-2px);
  }
}

.select2-container--theme-select2-standard .select2-search__field {
  // font-family: @font;
  // width      : 100%;
  // border     : 1px solid #9D9CAF;
  // background : transparent;

  // font-family  : @font;
  // color        : #9D9CAF;
  // font-size    : 16px;
  // font-weight  : 700;
  // padding      : 8px 12px;
  // border-radius: 16px;
}

// Для очистки результатов выбора
.form__select2-standard .select2-container .select2-selection--single .select2-selection__clear {
  position : absolute;
  right    : 50px;
  font-size: 20px;
  top      : 50%;
  transform: translateY(-50%);
  color    : @color-red;
}

//***Служебные***//
.form__select2-standard .select2-container--theme-select2-standard.select2-container--open {
  border-radius: 0;
}

.form__select2-standard .select2-dropdown {
  border : none;  
}

.form__select2-standard .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left : 0;
  padding-right: 0;
}

.select2-container--theme-select2-standard.select2-container--open .select2-dropdown--below {
  border    : 0;
  background: transparent;
}

.select2-container--theme-select2-standard .select2-search--dropdown {
  // display: none;
}

.form__select2-standard .select2.select2-container {
  width: 100% !important;
}

.select2-dropdown {
  z-index: 1151;
}