* {
  margin    : 0;
  padding   : 0;
  border    : none;
  box-sizing: border-box;
  outline   : none;
}

main,
article,
section,
footer,
header,
aside,
canvas {
  display: block;
}

section,
header,
footer {
  position: relative;
}

html {
  height: 100%;
}

body {
  min-width       : 320px;
  min-height      : 100%;
  background-color: #ffffff;

  font-family   : @font;
  font-size     : 14px;
  line-height   : 1;
  font-weight   : 400;
  letter-spacing: 0em;
  color         : @color-black;

  position: relative;

  @media screen and (max-width: @inner) { position: relative;}  /*inner - 1160px + 30px*2 = 1220px */
  @media screen and (max-width: @tablet) { position: relative;}  /*lg - 1200px*/
  @media screen and (max-width: @ipad) { position: relative;}  /*md - 960px*/
  @media screen and (max-width: @mob) { position: relative;}  /*sm - 670px*/
  @media screen and (max-width: @mob-small) { position: relative;} /*мобилки вертикальные - 480px*/
  @media screen and (max-width: 350px) { position: relative; }

  @media screen and (min-width: @min-mob) {    position: relative;  }
  @media screen and (min-width: @min-ipad) { position: relative;}
  @media screen and (min-width: @min-tablet) { position: relative;}

  @media screen and (min-width: @min-ipad) and (max-height: 600px) {  position: relative;  }
}

/* + inner*/
section:after {
  content: '';
  display: block;
  clear  : both;
}

button,
input,
select {
  font-family: @font;
  font-weight: 400;
}

button {
  cursor          : pointer;
  background-color: transparent;
  font-size       : inherit;
  font-weight     : inherit;
}

h1,
h2,
h3 {
  font-family: @font-title;
  font-weight: 700;
  line-height: 1.3;
}

h1 {
  font-size: 32px;

  @media screen and (max-width: @mob) {
    font-size: 24px;
  }
}

h2 {
  font-size  : 32px;

  @media screen and (max-width: @mob) {
    font-size: 24px;
  }
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
  display  : block;
}

svg {
  max-width: 100%;
  display  : inline-block;
}

a {
  color: inherit;
}