.promo {
  position: relative;
  z-index : 5;

  &__picture {
    width     : 100%;
    height    : 450px;
    object-fit: cover;

    @media screen and (max-width: @mob) {
      height    : auto;
      min-height: 540px;
    }
  }

  &__info-block {
    position: absolute;
    width   : 100%;
    top     : 48px;
    left    : 0;
    right   : 0;

    @media screen and (max-width: @mob) {
      top: 50px;
    }

    @media screen and (max-width: 350px) {
      top: 30px;
    }
  }

  &__title {
    color         : #ffffff;
    font-size     : 48px;
    font-weight   : 700;
    line-height   : 1.2;
    padding-bottom: 60px;

    @media screen and (max-width: @tablet) {
      font-size     : 33px;
      padding-bottom: 30px;
    }

    @media screen and (max-width: @mob) {
      font-size     : 20px;
      line-height   : 1.5;
      padding-bottom: 36px;

      br {
        display: none;
      }
    }

    @media screen and (max-width: 350px) {
      padding-bottom: 20px;
    }
  }
}