.range {
  position: relative;

  @media screen and (max-width: 1300px) {
    margin-left : 10px;
    margin-right: 10px;
    width       : calc(100% - 20px);
  }

  &__value-item {
    position: relative;
  }

  &__slider {
    width: 100%;
  }
}

.range {
  .noUi-target {
    background   : #D9D9D9;
    border-radius: 0;
    border       : 0;
    box-shadow   : none;
    height       : 2px;
    cursor       : pointer;
  }

  .noUi-connect {
    background: @color-red;
  }

  .noUi-handle {}

  .noUi-handle:before,
  .noUi-handle:after {
    display: none;
  }

  .noUi-handle-lower {}

  .noUi-handle-upper {}

  .noUi-horizontal {}

  .noUi-horizontal .noUi-handle {
    width        : 14px;
    height       : 14px;
    background   : @color-red;
    border-radius: 50%;
    cursor       : pointer;
    border       : 0;
    box-shadow   : none;
    top          : -6px;
    right        : -7px;
    border       : 2px solid #ffffff;
    box-sizing   : border-box;
  }

  .noUi-horizontal .noUi-tooltip {
    display: none;
  }
}