.brands {
  padding-top   : 60px;
  padding-bottom: 50px;

  @media screen and (max-width: @mob) {
    padding-top   : 40px;
    padding-bottom: 30px;
  }

  &__title {
    font-size     : 36px;
    line-height   : 1.1;
    text-align    : center;
    padding-bottom: 40px;

    @media screen and (max-width: @mob) {
      padding-bottom: 20px;
      font-size     : 24px;
      line-height   : 1.3;
      text-align    : left;
    }
  }

  &__row {
    @media screen and (max-width: @mob) {
      margin-left : -10px;
      margin-right: -10px;
    }
  }

  &__col {
    @media screen and (max-width: @mob) {
      padding-left : 10px;
      padding-right: 10px;
    }
  }

  &__item-wrap {
    padding-bottom: 10px;
  }

  &__show-more-btn-wrap {
    padding-bottom: 10px;
  }

  &__show-more-btn {
    width          : 100%;
    height         : 60px;
    padding        : 10px 20px;
    border         : 1px solid #E0E1E7;
    border-radius  : 10px;
    display        : flex;
    align-items    : center;
    text-decoration: none;
    font-size      : 16px;
    font-weight    : 500;
    color          : @color-red;
    transition     : all 0.5s;

    span {
      padding-right: 5px;
    }

    svg {
      margin-top: 2px;
    }

    svg path {
      transition: all 0.5s;
    }

    @media screen and (max-width: @mob) {
      height : 40px;
      border : 0;
      padding: 0;
    }

    @media screen and (min-width: @min-tablet) {
      &:hover {
        background  : @color-red;
        border-color: @color-red;
        color       : #ffffff;

        svg path {
          stroke: #ffffff !important;
        }
      }
    }

    &.js-hide-more-items-btn svg {
      transform: rotate(180deg);
    }
  }
}