.car-mob-widget {
  display: none;

  @media screen and (max-width: @mob) {
    display   : block;
    padding   : 15px;
    position  : sticky;
    position  : -webkit-sticky;
    bottom    : 0;
    left      : 0;
    width     : 100%;
    z-index   : 999;
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(14, 25, 35, 0.12);
  }

  &__row {
    margin-left : -7px;
    margin-right: -7px;
  }

  &__col {
    padding-left : 7px;
    padding-right: 7px;

    &--credite {
      width: calc(100% - 74px);
    }

    &--call {
      width: 74px;
    }
  }

  &__btn-credite {
    display        : inline-flex;
    border-radius  : 10px;
    justify-content: center;
    align-items    : center;
    flex-direction : column;
    width          : 100%;
    height         : 60px;
    color          : #ffffff;
    text-align     : center;
    background     : @color-red;
    text-decoration: none;
    padding-bottom : 3px;
  }

  &__btn-credite-name {
    font-size  : 16px;
    font-weight: 500;
    line-height: 1.4;
  }

  &__btn-credite-value {
    font-size  : 12px;
    font-weight: 500;
    line-height: 1.3;
    padding-top: 3px;
  }

  &__btn-call {
    width          : 60px;
    height         : 60px;
    border-radius  : 10px;
    display        : inline-flex;
    align-items    : center;
    justify-content: center;
    background     : #0EA348;
    text-decoration: none;
  }
}