.btn {
  display        : inline-flex;
  align-content  : center;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  border         : 1px solid @color-red;
  color          : #ffffff;
  background     : @color-red;
  min-height     : 50px;
  max-width      : 100%;
  font-family    : @font;
  font-size      : 14px;
  line-height    : 1.2;
  font-weight    : 500;
  padding        : 8px 20px 5px;
  transition     : all 0.5s;
  text-decoration: none;
  position       : relative;
  z-index        : 1;
  transition     : all 0.5s;
  border-radius  : 10px;

  span {
    padding-right: 5px;
  }

  svg {
    margin-top: -2px;
    font-size : 0;
    transition: all 0.5s;

    path {
      transition: all 0.5s;
    }
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }
}

.btn--large {
  font-size: 18px;
}

.btn--red {

  @media screen and (min-width: @min-tablet) {
    &:hover {
      background: #ffffff;
      color     : @color-red;

      svg path {
        stroke: @color-red;
      }
    }
  }
}

.btn--green {
  background  : #0EA348;
  border-color: #0EA348;

  @media screen and (min-width: @min-tablet) {
    &:hover {
      background: #ffffff;
      color     : #0EA348;

      svg path {
        stroke: #0EA348;
      }
    }
  }
}

.btn--crystal {

  @media screen and (min-width: @min-tablet) {
    &:hover {
      background  : @color-black;
      color       : #ffffff;
      border-color: @color-black;
    }
  }
}

.btn--white {
  background  : #FFFFFF;
  color       : @color-gray;
  border-color: #ffffff;

  @media screen and (min-width: @min-tablet) {
    &:hover {
      background: transparent;
      color     : #ffffff;
    }
  }
}

.btn-more {
  display        : inline-flex;
  align-items    : center;
  font-size      : 14px;
  font-weight    : 500;
  line-height    : 1.3;
  text-decoration: none;
  color          : @color-red;

  span {
    max-width    : calc(100% - 24px);
    padding-right: 5px;
  }

  svg {
    width     : 24px;
    transition: all 0.5s;
  }

  &:hover {
    svg {
      transform: translateX(3px);
    }
  }
}