.partner-banks {
  padding-bottom: 60px;

  @media screen and (max-width: @mob) {
    padding-bottom: 20px;
  }

  &__title {
    padding-bottom: 30px;
    font-size     : 24px;
    line-height   : 1.3;

    @media screen and (max-width: @mob) {
      padding-bottom: 10px;
    }
  }

  &__list-wrap {
    @media screen and (max-width: @mob) {
      overflow    : hidden;
      margin-left : -15px;
      margin-right: -15px;
      width       : calc(100% + 30px);
    }
  }

  &__row {
    margin-left : -8px;
    margin-right: -8px;

    @media screen and (max-width: @mob) {
      flex-wrap    : nowrap;
      overflow-x   : auto;
      padding-left : 15px;
      padding-right: 15px;

      &::-webkit-scrollbar {
        width : 0;
        height: 0;
      }
    }
  }

  &__col {
    padding-bottom: 20px;
    padding-left  : 8px;
    padding-right : 8px;

    @media screen and (max-width: @mob) {
      padding-top: 20px;
    }
  }

  &__item {
    width          : 100%;
    height         : 70px;
    display        : flex;
    align-items    : center;
    justify-content: center;
    padding        : 20px;
    box-shadow     : 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
    border         : 1px solid #F8F9FB;
    border-radius  : 10px;

    @media screen and (max-width: @mob) {
      width : 146px;
      height: 60px;
    }
  }
}