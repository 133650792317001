.credite-page-form {
  &__title {
    padding-bottom: 20px;
    font-size     : 24px;
    line-height   : 1.3;
    font-weight   : 700;
    text-align    : center;
  }

  &__car-info {
    padding-bottom: 10px;
    border-bottom : 1px solid #EFF2F4;
    margin-bottom : 20px;
  }

  &__item-input {
    padding-bottom: 10px;
  }

  &__subtitle {
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
    padding-bottom: 10px;
  }

  &__send-block {
    padding-top: 10px;
  }

  &__btn {
    width    : 100%;
    font-size: 18px;
  }

  &__item-note {
    padding-top: 10px;
  }

  &__note {
    color      : @color-gray;
    font-size  : 12px;
    line-height: 1.3;
  }

  &__messange {
    font-size  : 12px;
    width      : 100%;
    padding-top: 10px;
    line-height: 1.3;
  }
}