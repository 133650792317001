.contacts {
  padding-bottom: 50px;

  @media screen and (max-width: @mob) {
    padding-bottom: 40px;
  }

  &__title {
    padding-bottom: 20px;
  }

  &__widget {
    margin-bottom: 40px;
    border       : 1px solid #E8E9ED;
    padding      : 33px 60px 13px;

    @media screen and (max-width: @tablet) {
      padding-left : 33px;
      padding-right: 33px;
    }

    @media screen and (max-width: @mob) {
      margin-bottom: 20px;
      padding      : 30px;
    }
  }

  &__widget-row {
    justify-content: space-between;
  }

  &__widget-col {
    padding-bottom: 20px;

    @media screen and (max-width: @mob) {
      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__widget-item {
    display  : flex;
    flex-wrap: wrap;
  }

  &__widget-icon-wrap {
    width    : 24px;
    font-size: 0;
  }

  &__widget-info-wrap {
    max-width   : calc(100% - 24px);
    padding-left: 10px;
  }

  &__widget-item-name {
    color      : #515456;
    font-size  : 14px;
    line-height: 1.4;
  }

  &__widget-item-value {
    font-size  : 16px;
    line-height: 1.5;
    font-weight: 500;

    a {
      text-decoration: none;
    }
  }

  &__info-wrap {
    padding-bottom: 10px;
  }

  &__info-title {
    padding-bottom: 20px;
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
  }

  &__info-item {
    padding-bottom: 10px;
  }

  &__info-item-name {
    font-size  : 14px;
    line-height: 1.4;
    color      : #515456;
  }

  &__info-item-value {
    font-size  : 16px;
    font-weight: 500;
    line-height: 1.5;
  }

}