// Для КАРТЫ!!!
.contacts-map {
  position  : relative;
  width     : 100%;
  height    : 400px;
  background: #cccccc;

  @media screen and (max-width: @mob) {
    position: relative;
    top     : auto;
    left    : auto;
    width   : 100%;
    height  : 250px;
  }

  &__map {
    width : 100%;
    height: 100%;
  }
}

ymaps[class$="search__suggest-item"] {
  font-family: @font;
}

ymaps[class$="-balloon__content"] {
  // max-width: 200px;
}

ymaps[class*="-balloon_layout_normal"] {
  transform: translateY(-20px) !important;
}

// ymaps-2-1-79-balloon ymaps-2-1-79-balloon_layout_normal ymaps-2-1-79-balloon_to_top ymaps-2-1-79-i-custom-scroll

// Серая карта
[class*="ymaps-2"][class*="-ground-pane"] {
  filter        : url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
}

.contacts {
  &__map-ballon {
    font-family: @font;
  }

  &__map-adress-name {
    font-weight   : 500;
    font-size     : 18px;
    line-height   : 1.2;
    color         : @color-black;
    font-family   : @font;
    padding-bottom: 5px;
  }

  &__map-adress {
    font-size  : 14px;
    line-height: 1.2;
    color      : @color-black;
    font-family: @font;
  }
}