.actions {
  padding-bottom: 50px;

  @media screen and (max-width: @mob) {
    padding-bottom: 10px;
  }

  &__title {
    padding-bottom: 30px;

    @media screen and (max-width: @mob) {
      padding-bottom: 20px;
    }
  }

  &__item-wrap {
    padding-bottom: 30px;
  }
}