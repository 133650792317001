.credite-page {
  padding-bottom: 40px;

  &__form-wrap {
    box-shadow   : 0px 10px 30px 0px rgba(14, 25, 35, 0.10);
    border-radius: 10px;
    background   : #ffffff;
    padding      : 25px 30px;

    @media screen and (max-width: @tablet) {
      padding-left : 20px;
      padding-right: 20px;
    }
  }
}