.action {
  padding-bottom: 80px;

  @media screen and (max-width: @mob) {
    padding-top   : 8px;
    padding-bottom: 40px;
  }

  &__title {
    padding-bottom: 30px;

    @media screen and (max-width: @mob) {
      padding-bottom: 20px;
    }
  }

  &__wrap {
    width        : 100%;
    max-width    : 950px;
    background   : #ffffff;
    border-radius: 10px;
    box-shadow   : 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
    padding      : 40px;

    @media screen and (max-width: @mob) {
      padding: 20px;
    }
  }

  &__preview-picture {
    width        : 100%;
    border-radius: 10px;
  }

  &__text-wrap {
    padding-top   : 20px;
    padding-bottom: 20px;
    font-size     : 16px;
    font-weight   : 500;
    line-height   : 1.5;

    @media screen and (max-width: @mob) {
      font-size  : 14px;
      line-height: 1.4;
    }

    h1,
    h2,
    h3,
    img {
      padding-bottom: 20px;
    }

    p {
      padding-bottom: 20px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    ul {
      list-style    : none;
      padding-bottom: 15px;

      li {
        padding-left  : 20px;
        padding-bottom: 5px;
        position      : relative;

        &::before {
          content      : '';
          display      : block;
          width        : 6px;
          height       : 6px;
          border-radius: 50%;
          background   : @color-black;
          position     : absolute;
          top          : 8px;
          left         : 0;
        }
      }
    }

    ol {
      padding-bottom     : 15px;
      list-style-position: inside;

      li {
        padding-bottom: 5px;
      }
    }
  }
}