.inner {
  width: 100%;
  max-width: @inner; //контейнер + 30*2px
  height   : 100%;
  margin   : 0 auto;
  padding  : 0 30px;

  @media screen and (max-width: @mob) {
    padding: 0 15px;
  }
}