.slider-photo-catalog-car {
  position: relative;

  .slick-list {
    @media screen and (max-width: @mob) {
      margin-left : -5px;
      margin-right: -5px;
    }
  }

  .slider-photo-catalog-car__item {
    @media screen and (max-width: @mob) {
      padding-left : 5px;
      padding-right: 5px;
    }
  }
}

// Slider Nav
.slider-photo-catalog-car {}

.slider-photo-catalog-car__dots {
  position: absolute;
  bottom  : 15px;
  left    : 25px;
  right   : 25px;
  margin  : auto;
  width   : calc(100% - 50px);

  @media screen and (max-width: @ipad) {
    width: calc(100% - 20px);
  }

  @media screen and (max-width: @mob) {
    display: none;
  }
}

.slider-photo-catalog-car__dots .slick-dots {
  display       : flex;
  flex-wrap     : wrap;
  align-content : center;
  align-items   : center;
  list-style    : none;
  pointer-events: auto;
  margin-left   : -5px;
  margin-right  : -5px;

  li {
    line-height: 0;
    font-size  : 0;
    margin     : 0;
    padding    : 0 5px;
    flex-grow  : 1;
  }

  button {
    font-size    : 0;
    width        : 100%;
    height       : 3px;
    border-radius: 2px;
    background   : #FFFFFF;
    position     : relative;
    transition   : all 0.2s;
    opacity      : 0.4;
    border-radius: 10px;

    @media screen and (min-width: @min-tablet) {
      &:hover {
        opacity: 1;
      }
    }
  }

  .slick-active button {
    opacity: 1;
  }
}