.car-page {
  padding-bottom: 60px;

  @media screen and (max-width: @mob) {
    padding-top   : 20px;
    padding-bottom: 25px;
  }

  &__col--credite {
    @media screen and (max-width: @mob) {
      display: none;
    }
  }

  &__name-block {
    padding-bottom: 20px;

    @media screen and (max-width: @mob) {
      padding-bottom: 0;
    }
  }

  &__name-info-wrap {
    display    : flex;
    align-items: center;
  }

  &__name {
    font-size  : 32px;
    font-weight: 700;
    line-height: 1.3;

    @media screen and (max-width: @mob) {
      font-size  : 18px;
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &__year {
    padding-left: 25px;
    position    : relative;
    font-size   : 24px;
    font-weight : 500;
    line-height : 1.3;
    color       : #A2A5A8;

    @media screen and (max-width: @mob) {
      font-size  : 16px;
      line-height: 1.5;
    }

    &::before {
      content      : '';
      display      : block;
      position     : absolute;
      width        : 5px;
      height       : 5px;
      border-radius: 50%;
      background   : #A2A5A8;
      top          : 50%;
      transform    : translateY(-50%);
      left         : 10px;
    }
  }

  &__price-wrap {
    padding-bottom: 20px;
  }

  &__price {
    display     : flex;
    align-items : flex-end;
    flex-wrap   : wrap;
    margin-left : -7px;
    margin-right: -7px;
  }

  &__price-item {
    padding-left : 7px;
    padding-right: 7px;
  }

  &__new-price {
    font-size  : 32px;
    line-height: 1.3;
    font-weight: 700;

    @media screen and (max-width: @mob) {
      font-size: 24px;
    }
  }

  &__old-price {
    text-decoration: line-through;
    font-size      : 20px;
    font-weight    : 500;
    line-height    : 1.5;
    color          : @color-gray;

    @media screen and (max-width: @mob) {
      font-size: 16px;
    }
  }

  &__photogallery-wrap {
    padding-bottom: 20px;
  }

  &__specification-wrap {
    padding-top: 10px;
  }

  &__btn-call-wrap {
    padding-bottom: 20px;

    @media screen and (max-width: @ipad) {
      padding-top   : 20px;
      padding-bottom: 30px;
    }

    @media screen and (max-width: @mob) {
      display: none;
    }
  }

  &__btn-call {
    display        : flex;
    align-items    : center;
    justify-content: center;
    width          : 100%;
    height         : 70px;
    border         : 1px solid #E0E1E7;
    border-radius  : 10px;
    text-decoration: none;
    transition     : all 0.5s;
    background     : #ffffff;
  }

  &__btn-call-info {
    display        : flex;
    align-items    : center;
    justify-content: center;
  }

  &__btn-call-icon {
    width           : 36px;
    height          : 36px;
    display         : flex;
    align-items     : center;
    justify-content : center;
    background-color: @color-red;
    border-radius   : 50%;
    transition      : all 0.5s;

    svg path {
      transition: all 0.5s;
    }
  }

  &__btn-call-text {
    max-width   : calc(100% - 36px);
    padding-left: 15px;
    font-size   : 18px;
    font-weight : 700;
    color       : @color-red;
    transition  : all 0.5s;
  }

  &__legal-info {
    @media screen and (max-width: @mob) {
      padding-top: 25px;
    }
  }

  &__legal-title {
    padding-bottom: 15px;
    font-size     : 20px;
    font-weight   : 700;
    line-height   : 1.5;

    @media screen and (max-width: @mob) {
      font-size: 18px;
    }
  }

  &__legal-list {
    list-style: none;

    li {
      position      : relative;
      min-height    : 32px;
      padding-bottom: 15px;
      padding-left  : 47px;
      font-size     : 16px;
      line-height   : 1.5;
      color         : #313335;
      font-weight   : 500;
      padding-top   : 2px;

      @media screen and (max-width: @mob) {
        font-size: 14px;
      }

      &::before {
        content        : '';
        display        : block;
        width          : 32px;
        height         : 32px;
        position       : absolute;
        top            : 0;
        left           : 0;
        background     : url(../images/icons/checkmark.svg) no-repeat center;
        background-size: contain;
      }
    }
  }

}

.car-page__btn-call {
  @media screen and (min-width: @min-tablet) {
    &:hover {
      background  : @color-red;
      border-color: @color-red;

      .car-page__btn-call-icon {
        background: #ffffff;

        svg path {
          stroke: @color-red;
        }
      }

      .car-page__btn-call-text {
        color: #ffffff;
      }
    }
  }
}