.credite-single-car {
  box-shadow   : 0px 10px 30px 0px rgba(14, 25, 35, 0.10);
  border-radius: 10px;
  background   : #ffffff;
  padding      : 25px 30px;

  @media screen and (max-width: @tablet) {
    padding-left : 20px;
    padding-right: 20px;
  }

  &__title {
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
    padding-bottom: 10px;
  }

  &__car-info {
    padding-bottom: 10px;
    border-bottom : 1px solid #EFF2F4;
    margin-bottom : 20px;
  }

  &__item-input {
    padding-bottom: 10px;
  }

  &__subtitle {
    font-size     : 16px;
    font-weight   : 700;
    line-height   : 1.5;
    padding-bottom: 10px;
  }

  &__send-block {
    padding-top: 10px;
  }

  &__btn {
    width    : 100%;
    font-size: 18px;
  }

  &__item-note {
    padding-top: 10px;
  }

  &__note {
    color      : @color-gray;
    font-size  : 12px;
    line-height: 1.3;
  }

  &__messange {
    font-size  : 12px;
    width      : 100%;
    padding-top: 10px;
    line-height: 1.3;
  }

  &__input-range-wrap {
    position: relative;
  }

  &__range-price {
    position: absolute;
    bottom  : 0;
    left    : 15px;
    right   : 15px;
  }

  &__input {
    text-align: center;
  }

  &__label {
    padding-bottom: 5px;
    font-size     : 14px;
    line-height   : 1.4;
    color         : #515456;
  }

  &__result-wrap {
    padding-top: 10px;
  }

  &__result-item-wrap {
    padding-bottom: 10px;
  }

  &__result-item {
    display: flex;
  }

  &__result-name {
    width      : 190px;
    font-size  : 16px;
    line-height: 1.5;
    color      : #313335;
  }

  &__result-value {
    width       : calc(100% - 190px);
    padding-left: 20px;
    font-size   : 16px;
    font-weight : 500;
    line-height : 1.5;
  }
}