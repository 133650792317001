.footer-menu {
  &__list {
    display        : flex;
    flex-wrap      : wrap;
    justify-content: space-between;
    list-style     : none;
  }

  &__item {
    position      : relative;
    padding-bottom: 10px;
    flex-grow     : 1;

    @media screen and (max-width: @mob) {
      padding-top   : 5px;
      padding-bottom: 5px;
    }
  }

  &__link {
    font-size      : 18px;
    line-height    : 1.5;
    font-weight    : 700;
    color          : #F8FAFB;
    position       : relative;
    text-decoration: none;
    transition     : all 0.5s;
    display        : inline-block;

    @media screen and (max-width: @mob) {
      font-size  : 14px;
      line-height: 1.4;
    }
  }

}

.footer-menu__item:hover {
  @media screen and (min-width: @min-tablet) {
    .footer-menu__link {
      color: @color-red;
    }
  }
}