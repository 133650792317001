.burger {
  z-index : 999;
  position: relative;

  display        : flex;
  align-content  : center;
  align-items    : center;
  justify-content: center;
  width          : 18px;
  height         : 18px;
  margin-left    : auto;

  &__btn-icon {
    width   : 18px;
    height  : 14px;
    position: relative;
    display : block;
  }

  &__btn-icon-line {
    width     : 100%;
    height    : 2px;
    display   : block;
    background: #313335;
    position  : absolute;
    transition: all 0.5s;
  }

  &__btn-icon-line:first-child {
    top : 0;
    left: 0;
  }

  &__btn-icon-line:nth-child(2) {
    top      : 50%;
    transform: translateY(-50%);
    left     : 0;
  }

  &__btn-icon-line:last-child {
    bottom: 0;
    left  : 0;
  }

  &__btn-name {
    opacity    : 0;
    object-fit : none;
    font-size  : 0;
    text-indent: -9999px;
    position   : absolute;
  }
}

.burger--open {
  .burger__btn-icon {
    height: 14px;
  }

  .burger__btn-icon-line {
    // background: #ffffff;
  }

  .burger__btn-icon-line:first-child {
    transform: translateY(6px) rotate(45deg); //высота блока (14px) минус ширина линии (2px) разделить на 2
  }

  .burger__btn-icon-line:nth-child(2) {
    opacity: 0;
  }

  .burger__btn-icon-line:last-child {
    transform: translateY(-6px) rotate(-45deg);
  }
}