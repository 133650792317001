.social-links {
  &__row {
    display     : flex;
    flex-wrap   : wrap;
    margin-left : -5px;
    margin-right: -5px;
  }

  &__col {
    padding-left : 5px;
    padding-right: 5px;
  }

  &__item {
    display        : flex;
    align-content  : center;
    align-items    : center;
    justify-content: center;
    width          : 32px;
    height         : 32px;
    border-radius  : 5px;
    background     : @color-green;
    transition     : all 0.5s;

    @media screen and (min-width: @min-tablet) {
      &:hover {
        background: @color-black;
      }
    }
  }
}