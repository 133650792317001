//input
.form__input {
  font-family  : @font;
  width        : 100%;
  height       : 50px;
  background   : #F8F9FB;
  border       : 1px solid #E0E1E7;
  border-radius: 10px;

  font-size     : 16px;
  font-weight   : 500;
  color         : @color-black;
  padding-left  : 15px;
  padding-right : 15px;
  box-sizing    : border-box;

  -webkit-appearance: none;
  -moz-appearance   : none;
  appearance        : none;

  &::-webkit-input-placeholder {
    opacity: 1;
    color  : #767980;
  }

  &::-moz-placeholder {
    opacity: 1;
    color  : #767980;
  }

  /* Firefox 19+ */
  &:-moz-placeholder {
    opacity: 1;
    color  : #767980;
  }

  /* Firefox 18- */
  &:-ms-input-placeholder {
    opacity: 1;
    color  : #767980;
  }

  @media screen and (max-width: @mob) {
    height   : 48px;
    font-size: 16px;
  }
}

// checkbox button
.form__label-checkbox {
  cursor  : pointer;
  position: relative;
  display : inline-block;
}

.form__input-checkbox {
  display   : block;
  visibility: visible;
  opacity   : 0;
  position  : absolute;
}

.form__label-check-wrap {
  display: inline-flex;
}

.form__input-checkbox:checked+.form__label-check-wrap {
  position: relative;
}

.form__check-check {
  width   : 24px;
  height  : 24px;
  position: relative;
  border  : 1px solid @color-gray;
  display : block;
}

.form__check-text {
  font-weight : 500;
  font-size   : 15px;
  line-height : 1.54;
  padding-left: 12px;
  max-width   : calc(100% - 24px);
  display     : block;
  text-align  : left;
  color       : #ffffff;

  @media screen and (max-width: @mob) {
    font-size     : 12px;
    padding-bottom: 0;
  }
}

.form__input-checkbox:checked+.form__label-check-wrap .form__check-check {
  background: url(../images/icons/check.svg) no-repeat center;

  @media screen and (max-width: @mob) {
    background-size: contain;
  }
}

.js-form-input-error.form__input-checkbox+.form__label-check-wrap .form__check-check {
  border-color: @color-red;
}

// .js-form-input-error.form__input-checkbox+.form__label-check-wrap .form__check-text {
//   color: @color-red;
// }

// Select
.form__select {
  font-family  : @font;
  width        : 100%;
  height       : 50px;
  font-size    : 16px;
  font-weight  : 500;
  line-height  : 1.2;
  color        : #767980;
  padding-left : 15px;
  padding-right: 35px;
  padding-top  : 3px;
  background   : #F8F9FB;
  border       : 1px solid #E0E1E7;
  border-radius: 10px;

  display      : flex;
  align-content: center;
  align-items  : center;

  cursor            : pointer;
  -webkit-appearance: none;
  -moz-appearance   : none;
  -ms-appearance    : none;
  appearance        : none !important;
}

.form__select::-ms-expand {
  display: none;
}

.form__for-select {
  position: relative;
}

.form__for-select:before {
  position      : absolute;
  top           : 0;
  right         : 15px;
  bottom        : 0;
  width         : 20px;
  height        : 20px;
  margin        : auto;
  content       : '';
  pointer-events: none;
  background    : url(../images/icons/arrow-select.svg) no-repeat right center, #F8F9FB;
}

.form__select option {
  font-size  : 16px;
  padding    : 5px;
  font-weight: 400;
  background : #F8F9FB;
}

.form__select--ok {
  color: @color-black;
}

//Textarea
.form__textarea {
  font-family  : @font;
  width        : 100%;
  height       : 108px;
  background   : #F8F9FB;
  border       : 1px solid #E0E1E7;
  border-radius: 10px;
  display      : block;

  font-size  : 16px;
  font-weight: 500;
  color      : @color-black;
  padding    : 13px 15px;

  // -webkit-appearance: none;
  // -moz-appearance   : none;
  // appearance        : none;
  // resize            : none;

  &::-webkit-input-placeholder {
    opacity: 1;
    color  : #767980;
  }

  &::-moz-placeholder {
    opacity: 1;
    color  : #767980;
  }

  /* Firefox 19+ */
  &:-moz-placeholder {
    opacity: 1;
    color  : #767980;
  }

  /* Firefox 18- */
  &:-ms-input-placeholder {
    opacity: 1;
    color  : #767980;
  }

  @media screen and (max-width: @mob) {
    height: 180px;
  }

}