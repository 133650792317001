.catalog-page {
  padding-bottom: 60px;
  background    : #F8FAFB;

  @media screen and (max-width: @mob) {
    padding-bottom: 30px;
  }

  &__row {
    margin-left : -10px;
    margin-right: -10px;
  }

  &__col {
    padding-left : 10px;
    padding-right: 10px;
  }

  &__title {
    padding-bottom: 23px;
    font-size     : 40px;
    line-height   : 1.2;

    @media screen and (max-width: @tablet) {
      font-size: 36px;
    }

    @media screen and (max-width: @mob) {
      font-size     : 24px;
      padding-bottom: 20px;
      line-height   : 1.3;
    }
  }

  &__filter-wrap {
    padding-bottom: 30px;
  }
}