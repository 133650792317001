.footer {
  background: #0A1B28;

  &__wrap {
    padding-top   : 40px;
    padding-bottom: 40px;
    color         : #ffffff;

    @media screen and (max-width: @mob) {
      padding-top   : 30px;
      padding-bottom: 30px;
    }
  }

  &__logo-subtitle-wrap {
    padding-top: 10px;

    @media screen and (max-width: @ipad) {
      padding-top   : 5px;
      padding-bottom: 25px;
    }
  }

  &__logo-subtitle {
    color      : #F8FAFB;
    font-size  : 16px;
    line-height: 1.5;

    @media screen and (max-width: @mob) {
      font-size  : 14px;
      line-height: 1.4;
    }
  }

  &__menu {
    @media screen and (max-width: @ipad) {
      padding-bottom: 15px;
    }
  }

  &__contacts-wrap {
    @media screen and (max-width: @ipad) {
      display        : flex;
      justify-content: space-between;
      align-items    : center;
      flex-wrap      : wrap;
    }
  }

  &__contacts-item {
    padding-bottom: 5px;

    @media screen and (max-width: @ipad) {
      padding-bottom: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__contact-phone {
    font-size      : 18px;
    font-weight    : 700;
    line-height    : 1.5;
    text-decoration: none;

    @media screen and (max-width: @mob) {
      font-size: 16px;
    }
  }

  &__contact-email {
    font-size      : 16px;
    color          : @color-red;
    line-height    : 1.5;
    text-decoration: none;
  }

  &__legal-wrap {
    background    : rgba(255, 255, 255, 0.05);
    padding-top   : 20px;
    padding-bottom: 20px;
  }

  &__legal-row {
    justify-content: space-between;
    align-items    : center;
  }

  &__copyright {
    color      : #E0E1E7;
    font-size  : 14px;
    line-height: 1.4;
  }

  &__legal-link-item {
    @media screen and (max-width: @mob) {
      padding-top: 10px;
    }
  }

  &__legal-link {
    display        : inline-block;
    text-decoration: none;
    color          : #E0E1E7;
    font-size      : 14px;
    line-height    : 1.4;
    transition     : all 0.5s;

    @media screen and (min-width: @min-tablet) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}