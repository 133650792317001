@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-v18-latin_cyrillic-regular.woff2') format('woff2'), url('../fonts/montserrat-v18-latin_cyrillic-regular.woff') format('woff'), url('../fonts/montserrat-v18-latin_cyrillic-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-v18-latin_cyrillic-500.woff2') format('woff2'), url('../fonts/montserrat-v18-latin_cyrillic-500.woff') format('woff'), url('../fonts/montserrat-v18-latin_cyrillic-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-v18-latin_cyrillic-700.woff2') format('woff2'), url('../fonts/montserrat-v18-latin_cyrillic-700.woff') format('woff'), url('../fonts/montserrat-v18-latin_cyrillic-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  outline: none;
}
main,
article,
section,
footer,
header,
aside,
canvas {
  display: block;
}
section,
header,
footer {
  position: relative;
}
html {
  height: 100%;
}
body {
  min-width: 320px;
  min-height: 100%;
  background-color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0em;
  color: #111111;
  position: relative;
  /*inner - 1160px + 30px*2 = 1220px */
  /*lg - 1200px*/
  /*md - 960px*/
  /*sm - 670px*/
  /*мобилки вертикальные - 480px*/
}
@media screen and (max-width: 1230px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 960px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 670px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 480px) {
  body {
    position: relative;
  }
}
@media screen and (max-width: 350px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 671px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 961px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 1201px) {
  body {
    position: relative;
  }
}
@media screen and (min-width: 961px) and (max-height: 600px) {
  body {
    position: relative;
  }
}
/* + inner*/
section:after {
  content: '';
  display: block;
  clear: both;
}
button,
input,
select {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}
button {
  cursor: pointer;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
}
h1,
h2,
h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  line-height: 1.3;
}
h1 {
  font-size: 32px;
}
@media screen and (max-width: 670px) {
  h1 {
    font-size: 24px;
  }
}
h2 {
  font-size: 32px;
}
@media screen and (max-width: 670px) {
  h2 {
    font-size: 24px;
  }
}
strong {
  font-weight: 700;
}
img {
  max-width: 100%;
  display: block;
}
svg {
  max-width: 100%;
  display: inline-block;
}
a {
  color: inherit;
}
.inner {
  width: 100%;
  max-width: 1230px;
  height: 100%;
  margin: 0 auto;
  padding: 0 30px;
}
@media screen and (max-width: 670px) {
  .inner {
    padding: 0 15px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}
@media screen and (max-width: 670px) {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}
.row--center-vertical {
  align-content: center;
  align-items: center;
}
.col {
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 670px) {
  .col {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.col-1 {
  width: 8.33%;
}
@media screen and (max-width: 670px) {
  .col-1 {
    width: 100%;
  }
}
.col-2 {
  width: 16.66%;
}
@media screen and (max-width: 670px) {
  .col-2 {
    width: 100%;
  }
}
.col-3 {
  width: 25%;
}
@media screen and (max-width: 670px) {
  .col-3 {
    width: 100%;
  }
}
.col-4 {
  width: 33.33%;
}
@media screen and (max-width: 670px) {
  .col-4 {
    width: 100%;
  }
}
.col-5 {
  width: 41.66%;
}
@media screen and (max-width: 670px) {
  .col-5 {
    width: 100%;
  }
}
.col-6 {
  width: 50%;
}
@media screen and (max-width: 670px) {
  .col-6 {
    width: 100%;
  }
}
.col-7 {
  width: 58.33%;
}
@media screen and (max-width: 670px) {
  .col-7 {
    width: 100%;
  }
}
.col-8 {
  width: 66.66%;
}
@media screen and (max-width: 670px) {
  .col-8 {
    width: 100%;
  }
}
.col-9 {
  width: 75%;
}
@media screen and (max-width: 670px) {
  .col-9 {
    width: 100%;
  }
}
.col-10 {
  width: 83.33%;
}
@media screen and (max-width: 670px) {
  .col-10 {
    width: 100%;
  }
}
.col-11 {
  width: 91.66%;
}
@media screen and (max-width: 670px) {
  .col-11 {
    width: 100%;
  }
}
.col-12 {
  width: 100%;
}
@media screen and (max-width: 670px) {
  .col-12 {
    width: 100%;
  }
}
.col-five {
  width: 20%;
}
@media screen and (max-width: 670px) {
  .col-five {
    width: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .col-lg-1 {
    width: 8.33%;
  }
  .col-lg-2 {
    width: 16.66%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33%;
  }
  .col-lg-5 {
    width: 41.66%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.33%;
  }
  .col-lg-8 {
    width: 66.66%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33%;
  }
  .col-lg-11 {
    width: 91.66%;
  }
  .col-lg-12 {
    width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .col-md-1 {
    width: 8.33%;
  }
  .col-md-2 {
    width: 16.66%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-4 {
    width: 33.33%;
  }
  .col-md-5 {
    width: 41.66%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-7 {
    width: 58.33%;
  }
  .col-md-8 {
    width: 66.66%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-10 {
    width: 83.33%;
  }
  .col-md-11 {
    width: 91.66%;
  }
  .col-md-12 {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .col-sm-1 {
    width: 8.33%;
  }
  .col-sm-2 {
    width: 16.66%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33%;
  }
  .col-sm-5 {
    width: 41.66%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.33%;
  }
  .col-sm-8 {
    width: 66.66%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33%;
  }
  .col-sm-11 {
    width: 91.66%;
  }
  .col-sm-12 {
    width: 100%;
  }
}
.offset-1 {
  margin-left: 8.33%;
}
.offset-2 {
  margin-left: 16.66%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.33%;
}
.offset-5 {
  margin-left: 41.66%;
}
.offset-6 {
  margin-left: 50%;
}
@media screen and (max-width: 1200px) {
  .offset-lg-0 {
    margin-left: 0;
  }
}
@media screen and (max-width: 960px) {
  .offset-md-0 {
    margin-left: 0;
  }
}
@media screen and (max-width: 670px) {
  .offset-sm-0 {
    margin-left: 0;
  }
}
.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.body--on-popup {
  max-width: 100vw;
}
.body--on-open-menu {
  max-width: 100vw;
}
.body--on-open-menu .main:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(17, 17, 17, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
}
.main {
  flex-grow: 1;
}
.js-hide {
  display: none !important;
}
.js-show {
  display: block !important;
}
.js-tabs-info {
  display: none;
}
.js-tabs-info--open {
  display: block;
}
.js-accordion-info {
  display: none;
}
.js-form-messange {
  display: none;
}
.js-form-messange--error {
  color: #E70A32;
  display: block !important;
}
.js-form-messange--ok {
  color: #92CD0C;
  display: block !important;
}
.js-form-input-error {
  border-color: #E70A32 !important;
  color: #E70A32 !important;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.js-form-input-error::-webkit-input-placeholder {
  color: #E70A32 !important;
}
.js-form-input-error::-moz-placeholder {
  color: #E70A32 !important;
}
.js-form-input-error:-moz-placeholder {
  color: #E70A32 !important;
}
.js-form-input-error:-ms-input-placeholder {
  color: #E70A32 !important;
}
.js-filter-item-toggle {
  display: none;
}
.btn {
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #E70A32;
  color: #ffffff;
  background: #E70A32;
  min-height: 50px;
  max-width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 1.2;
  font-weight: 500;
  padding: 8px 20px 5px;
  text-decoration: none;
  position: relative;
  z-index: 1;
  transition: all 0.5s;
  border-radius: 10px;
}
.btn span {
  padding-right: 5px;
}
.btn svg {
  margin-top: -2px;
  font-size: 0;
  transition: all 0.5s;
}
.btn svg path {
  transition: all 0.5s;
}
.btn:hover svg {
  transform: translateX(5px);
}
.btn--large {
  font-size: 18px;
}
@media screen and (min-width: 1201px) {
  .btn--red:hover {
    background: #ffffff;
    color: #E70A32;
  }
  .btn--red:hover svg path {
    stroke: #E70A32;
  }
}
.btn--green {
  background: #0EA348;
  border-color: #0EA348;
}
@media screen and (min-width: 1201px) {
  .btn--green:hover {
    background: #ffffff;
    color: #0EA348;
  }
  .btn--green:hover svg path {
    stroke: #0EA348;
  }
}
@media screen and (min-width: 1201px) {
  .btn--crystal:hover {
    background: #111111;
    color: #ffffff;
    border-color: #111111;
  }
}
.btn--white {
  background: #FFFFFF;
  color: #767980;
  border-color: #ffffff;
}
@media screen and (min-width: 1201px) {
  .btn--white:hover {
    background: transparent;
    color: #ffffff;
  }
}
.btn-more {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  text-decoration: none;
  color: #E70A32;
}
.btn-more span {
  max-width: calc(100% - 24px);
  padding-right: 5px;
}
.btn-more svg {
  width: 24px;
  transition: all 0.5s;
}
.btn-more:hover svg {
  transform: translateX(3px);
}
.header {
  background: #FFFFFF;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1071;
  padding-top: 17px;
  padding-bottom: 17px;
  box-shadow: 0px 5px 20px 0px rgba(44, 41, 51, 0.1);
}
.header__row {
  align-items: center;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
}
@media screen and (max-width: 1200px) {
  .header__row {
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 670px) {
  .header__row {
    margin-left: 0;
    margin-right: 0;
  }
}
.header__col {
  padding-left: 20px;
  padding-right: 20px;
}
@media screen and (max-width: 1200px) {
  .header__col {
    padding-left: 0;
    padding-right: 0;
  }
}
@media screen and (max-width: 670px) {
  .header__col {
    padding-left: 0;
    padding-right: 0;
  }
}
.header__col--menu {
  flex-grow: 1;
}
.header__col--burger {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header__col--burger {
    display: block;
    text-align: right;
  }
}
@media screen and (max-width: 670px) {
}
.header__col--logo {
  z-index: 999;
  position: relative;
}
.header__col--btn-call {
  display: none;
}
@media screen and (max-width: 670px) {
  .header__col--btn-call {
    display: block;
    text-align: right;
    flex-grow: 1;
    padding-right: 30px;
    z-index: 999;
    position: relative;
  }
}
@media screen and (max-width: 670px) {
  .header__col--city {
    display: none;
  }
}
@media screen and (max-width: 670px) {
  .header__col--contacts {
    display: none;
  }
}
.header__mob {
  display: none;
}
@media screen and (max-width: 670px) {
  .header__mob {
    display: block;
    padding-top: 30px;
  }
}
.header__city {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
}
.header__city svg {
  width: 20px;
}
.header__city span {
  max-width: calc(100% - 20px);
  padding-left: 10px;
  color: #313335;
  font-size: 16px;
}
.header__contact {
  display: flex;
  flex-wrap: wrap;
}
.header__contact-icon {
  width: 24px;
  font-size: 0;
}
.header__contact-info {
  max-width: calc(100% - 24px);
  padding-left: 10px;
}
.header__contact-tel {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
  display: block;
}
.header__contact-note {
  color: #676767;
  font-size: 12px;
  line-height: 1.3;
  display: block;
}
.header__btn-call {
  display: inline-block;
  font-size: 0;
}
.header__mob-city-wrap {
  padding-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .body--on-open-menu .header::before {
    content: '';
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 5px 20px 0px rgba(44, 41, 51, 0.1);
    z-index: 10000;
    pointer-events: none;
  }
}
.menu__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -20px;
  margin-right: -20px;
}
@media screen and (max-width: 1200px) {
  .menu__list {
    display: block;
    margin: 0;
  }
}
.menu__item {
  position: relative;
  padding: 0 20px;
}
@media screen and (max-width: 1200px) {
  .menu__item {
    padding: 0;
  }
}
.menu__link {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  color: #111111;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;
}
@media screen and (max-width: 1200px) {
  .menu__link {
    padding: 10px 5px;
    display: block;
  }
}
@media screen and (min-width: 1201px) {
  .menu__item:hover .menu__link {
    color: #E70A32;
  }
}
.menu__link.active {
  color: #E70A32;
}
@media screen and (max-width: 1200px) {
  .burger-menu {
    pointer-events: none;
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 340px;
    max-width: 100%;
    max-height: 100vh;
    z-index: 600;
    overflow-y: auto;
  }
}
@media screen and (max-width: 670px) {
  .burger-menu {
    width: 100%;
    height: 100vh;
  }
}
@media screen and (max-width: 1200px) {
  .burger-menu__wrap {
    pointer-events: auto;
    background: #ffffff;
    padding: 20px 24px 30px;
    max-height: 100%;
    overflow-y: auto;
  }
}
@media screen and (max-width: 670px) {
  .burger-menu__wrap {
    min-height: 100%;
    border-radius: 0;
    padding: 20px 24px 80px;
    margin-top: 0;
    pointer-events: auto;
  }
}
.burger {
  z-index: 999;
  position: relative;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin-left: auto;
}
.burger__btn-icon {
  width: 18px;
  height: 14px;
  position: relative;
  display: block;
}
.burger__btn-icon-line {
  width: 100%;
  height: 2px;
  display: block;
  background: #313335;
  position: absolute;
  transition: all 0.5s;
}
.burger__btn-icon-line:first-child {
  top: 0;
  left: 0;
}
.burger__btn-icon-line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.burger__btn-icon-line:last-child {
  bottom: 0;
  left: 0;
}
.burger__btn-name {
  opacity: 0;
  object-fit: none;
  font-size: 0;
  text-indent: -9999px;
  position: absolute;
}
.burger--open .burger__btn-icon {
  height: 14px;
}
.burger--open .burger__btn-icon-line:first-child {
  transform: translateY(6px) rotate(45deg);
}
.burger--open .burger__btn-icon-line:nth-child(2) {
  opacity: 0;
}
.burger--open .burger__btn-icon-line:last-child {
  transform: translateY(-6px) rotate(-45deg);
}
@media screen and (max-width: 670px) {
  .logo__img {
    width: 140px;
  }
}
.footer {
  background: #0A1B28;
}
.footer__wrap {
  padding-top: 40px;
  padding-bottom: 40px;
  color: #ffffff;
}
@media screen and (max-width: 670px) {
  .footer__wrap {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.footer__logo-subtitle-wrap {
  padding-top: 10px;
}
@media screen and (max-width: 960px) {
  .footer__logo-subtitle-wrap {
    padding-top: 5px;
    padding-bottom: 25px;
  }
}
.footer__logo-subtitle {
  color: #F8FAFB;
  font-size: 16px;
  line-height: 1.5;
}
@media screen and (max-width: 670px) {
  .footer__logo-subtitle {
    font-size: 14px;
    line-height: 1.4;
  }
}
@media screen and (max-width: 960px) {
  .footer__menu {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 960px) {
  .footer__contacts-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
.footer__contacts-item {
  padding-bottom: 5px;
}
@media screen and (max-width: 960px) {
  .footer__contacts-item {
    padding-bottom: 0;
  }
}
.footer__contacts-item:last-child {
  padding-bottom: 0;
}
.footer__contact-phone {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.5;
  text-decoration: none;
}
@media screen and (max-width: 670px) {
  .footer__contact-phone {
    font-size: 16px;
  }
}
.footer__contact-email {
  font-size: 16px;
  color: #E70A32;
  line-height: 1.5;
  text-decoration: none;
}
.footer__legal-wrap {
  background: rgba(255, 255, 255, 0.05);
  padding-top: 20px;
  padding-bottom: 20px;
}
.footer__legal-row {
  justify-content: space-between;
  align-items: center;
}
.footer__copyright {
  color: #E0E1E7;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (max-width: 670px) {
  .footer__legal-link-item {
    padding-top: 10px;
  }
}
.footer__legal-link {
  display: inline-block;
  text-decoration: none;
  color: #E0E1E7;
  font-size: 14px;
  line-height: 1.4;
  transition: all 0.5s;
}
@media screen and (min-width: 1201px) {
  .footer__legal-link:hover {
    text-decoration: underline;
  }
}
.footer-menu__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
}
.footer-menu__item {
  position: relative;
  padding-bottom: 10px;
  flex-grow: 1;
}
@media screen and (max-width: 670px) {
  .footer-menu__item {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.footer-menu__link {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
  color: #F8FAFB;
  position: relative;
  text-decoration: none;
  transition: all 0.5s;
  display: inline-block;
}
@media screen and (max-width: 670px) {
  .footer-menu__link {
    font-size: 14px;
    line-height: 1.4;
  }
}
@media screen and (min-width: 1201px) {
  .footer-menu__item:hover .footer-menu__link {
    color: #E70A32;
  }
}
.popup {
  position: fixed;
  z-index: 1101;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  height: 100vh;
  margin: auto;
  padding: 0;
  background: rgba(17, 17, 17, 0.5);
}
@media screen and (max-width: 670px) {
  .popup {
    background: rgba(17, 17, 17, 0.5);
  }
}
.popup__container {
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100% - 80px);
  max-width: 1230px;
  max-height: calc(100vh - 80px);
  background: transparent;
  padding: 0;
  padding-top: 34px;
}
@media screen and (min-width: 1201px) {
  .popup__container::-webkit-scrollbar {
    width: 4px;
  }
  .popup__container::-webkit-scrollbar-track {
    border-radius: 20px;
    background-color: transparent;
  }
  .popup__container::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background-color: #767980;
  }
}
@media screen and (max-width: 960px) {
  .popup__container {
    width: calc(100% - 60px);
    max-height: calc(100vh - 114px);
  }
}
@media screen and (max-width: 670px) {
  .popup__container {
    width: calc(100% - 48px);
    max-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
  }
}
.popup__container--standard {
  max-width: 370px;
}
.popup__container--promo {
  max-width: 750px;
}
.popup__inner {
  height: 100%;
  position: relative;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  border-radius: 10px;
  border: 0;
  overflow-y: auto;
}
@media screen and (max-width: 670px) {
  .popup__inner {
    overflow-y: auto;
  }
}
.popup__close {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 5;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .popup__close {
    top: 0;
    right: 0;
  }
}
.popup__close svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  width: 100%;
  height: 100%;
}
.popup__info-wrap {
  padding: 25px 30px;
}
@media screen and (max-width: 670px) {
  .popup__info-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.popup__info-wrap--promo {
  padding: 0;
}
@media screen and (max-width: 670px) {
  .popup__info-wrap--promo {
    padding: 0;
  }
}
.popup__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 20px;
}
.popup__subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
}
.popup__for-btn {
  text-align: center;
}
.popup__btn {
  width: 100%;
  font-size: 18px;
}
.popup-form {
  position: relative;
}
.popup-form__title {
  padding-bottom: 20px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
  text-align: center;
}
.popup-form__subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
}
.popup-form__car-info {
  padding-bottom: 10px;
  border-bottom: 1px solid #EFF2F4;
  margin-bottom: 20px;
}
.popup-form__item-input {
  padding-bottom: 10px;
}
.popup-form__send-block {
  padding-top: 10px;
}
.popup-form__btn {
  width: 100%;
  font-size: 18px;
}
.popup-form__item-note {
  padding-top: 10px;
}
.popup-form__note {
  color: #767980;
  font-size: 12px;
  line-height: 1.3;
}
.popup-form__messange {
  font-size: 12px;
  width: 100%;
  padding-top: 10px;
  line-height: 1.3;
}
.popup__title + .popup__subtitle {
  margin-top: -10px;
}
.popup-contact__info {
  padding-bottom: 10px;
  border-bottom: 1px solid #EFF2F4;
  margin-bottom: 20px;
}
.popup-contact__btn {
  width: 100%;
  font-size: 18px;
}
.popup-contact__phone-wrap {
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
}
.popup-contact__phone {
  text-decoration: none;
}
.popup-contact__time {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: #767980;
}
.popup-contact__subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 5px;
}
.popup-contact__address {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: #E70A32;
}
.popup-contact__btn-wrap {
  padding-top: 20px;
}
.popup-promo__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.popup-promo__picture-block {
  width: 48%;
}
@media screen and (max-width: 670px) {
  .popup-promo__picture-block {
    width: 100%;
  }
}
.popup-promo__info-block {
  width: 52%;
}
@media screen and (max-width: 670px) {
  .popup-promo__info-block {
    width: 100%;
  }
}
.popup-promo__picture {
  width: 100%;
}
.popup-promo__info-wrap {
  padding: 20px 40px;
}
@media screen and (max-width: 670px) {
  .popup-promo__info-wrap {
    padding: 20px 20px 40px;
  }
}
.popup-promo__title {
  padding-bottom: 10px;
  font-size: 32px;
  line-height: 1.2;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .popup-promo__title {
    font-size: 24px;
  }
}
.popup-promo__text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.popup-promo__btn-wrap {
  padding-top: 30px;
}
.popup-promo__btn {
  width: 100%;
}
.form__input {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 50px;
  background: #F8F9FB;
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.form__input::-webkit-input-placeholder {
  opacity: 1;
  color: #767980;
}
.form__input::-moz-placeholder {
  opacity: 1;
  color: #767980;
}
.form__input:-moz-placeholder {
  opacity: 1;
  color: #767980;
}
.form__input:-ms-input-placeholder {
  opacity: 1;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .form__input {
    height: 48px;
    font-size: 16px;
  }
}
.form__label-checkbox {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.form__input-checkbox {
  display: block;
  visibility: visible;
  opacity: 0;
  position: absolute;
}
.form__label-check-wrap {
  display: inline-flex;
}
.form__input-checkbox:checked + .form__label-check-wrap {
  position: relative;
}
.form__check-check {
  width: 24px;
  height: 24px;
  position: relative;
  border: 1px solid #767980;
  display: block;
}
.form__check-text {
  font-weight: 500;
  font-size: 15px;
  line-height: 1.54;
  padding-left: 12px;
  max-width: calc(100% - 24px);
  display: block;
  text-align: left;
  color: #ffffff;
}
@media screen and (max-width: 670px) {
  .form__check-text {
    font-size: 12px;
    padding-bottom: 0;
  }
}
.form__input-checkbox:checked + .form__label-check-wrap .form__check-check {
  background: url(../images/icons/check.svg) no-repeat center;
}
@media screen and (max-width: 670px) {
  .form__input-checkbox:checked + .form__label-check-wrap .form__check-check {
    background-size: contain;
  }
}
.js-form-input-error.form__input-checkbox + .form__label-check-wrap .form__check-check {
  border-color: #E70A32;
}
.form__select {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #767980;
  padding-left: 15px;
  padding-right: 35px;
  padding-top: 3px;
  background: #F8F9FB;
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  display: flex;
  align-content: center;
  align-items: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none !important;
}
.form__select::-ms-expand {
  display: none;
}
.form__for-select {
  position: relative;
}
.form__for-select:before {
  position: absolute;
  top: 0;
  right: 15px;
  bottom: 0;
  width: 20px;
  height: 20px;
  margin: auto;
  content: '';
  pointer-events: none;
  background: url(../images/icons/arrow-select.svg) no-repeat right center, #F8F9FB;
}
.form__select option {
  font-size: 16px;
  padding: 5px;
  font-weight: 400;
  background: #F8F9FB;
}
.form__select--ok {
  color: #111111;
}
.form__textarea {
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 108px;
  background: #F8F9FB;
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: #111111;
  padding: 13px 15px;
  /* Firefox 19+ */
  /* Firefox 18- */
}
.form__textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #767980;
}
.form__textarea::-moz-placeholder {
  opacity: 1;
  color: #767980;
}
.form__textarea:-moz-placeholder {
  opacity: 1;
  color: #767980;
}
.form__textarea:-ms-input-placeholder {
  opacity: 1;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .form__textarea {
    height: 180px;
  }
}
.form__select2-standard {
  position: relative;
}
.form__select2-standard:before {
  content: none;
}
.form__select2-standard .select2-container {
  background: transparent;
}
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single {
  position: relative;
  height: 54px;
  padding: 5px 24px 5px 24px;
  border: 1px solid #767980;
  border-radius: 0;
  align-content: center;
  align-items: center;
  background: transparent;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 670px) {
  .form__select2-standard .select2-container--theme-select2-standard .select2-selection--single {
    height: 48px;
  }
}
.form__select2-standard .select2-container--theme-select2-standard .select2-selection__placeholder {
  font-family: 'Montserrat', sans-serif;
  color: #767980;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 670px) {
  .form__select2-standard .select2-container--theme-select2-standard .select2-selection__placeholder {
    font-size: 16px;
  }
}
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__rendered {
  font-family: 'Montserrat', sans-serif;
  color: #767980;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 670px) {
  .form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__rendered {
    font-size: 16px;
  }
}
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__arrow {
  position: static;
}
.form__select2-standard .select2-container--theme-select2-standard .select2-selection--single .select2-selection__arrow b {
  position: absolute;
  border: none;
  width: 18px;
  height: 10px;
  margin: auto;
  content: '';
  pointer-events: none;
  background-image: url(../images/icons/icon-more.svg);
  background-position: right center;
  background-repeat: no-repeat;
  transition: transform 0.5s ease-in-out;
  top: 0;
  bottom: 0;
  right: 24px;
}
.form__select2-standard .select2-container--theme-select2-standard.select2-container--open .select2-selection--single .select2-selection__arrow b {
  transform: rotateX(180deg);
}
.select2-container--theme-select2-standard .select2-results__options {
  padding: 0;
  position: relative;
  background: #111111;
  border-radius: 0;
  border: 1px solid #767980;
  border-top: 0;
  max-height: 300px;
  overflow-y: auto;
}
@media screen and (max-width: 670px) {
  .select2-container--theme-select2-standard .select2-results__options {
    max-height: 60vh;
  }
}
@media screen and (min-width: 1201px) {
  .select2-container--theme-select2-standard .select2-results__options::-webkit-scrollbar {
    width: 4px;
  }
  .select2-container--theme-select2-standard .select2-results__options::-webkit-scrollbar-track {
    background: #E0E0E0;
  }
  .select2-container--theme-select2-standard .select2-results__options::-webkit-scrollbar-thumb {
    background: #9D9CAF;
  }
}
.select2-container--theme-select2-standard .select2-results__option {
  font-family: 'Montserrat', sans-serif;
  color: #767980;
  font-size: 18px;
  padding: 15px 24px;
  text-transform: uppercase;
  text-align: center;
}
@media screen and (max-width: 670px) {
  .select2-container--theme-select2-standard .select2-results__option {
    font-size: 16px;
  }
}
.select2-container--theme-select2-standard .select2-results__option--highlighted:hover {
  color: #767980;
  background: rgba(217, 217, 216, 0.5);
}
.select2-container--theme-select2-standard .select2-results__option[aria-selected=true] {
  color: #767980;
}
.form__select2-standard .js-form-input-error + .select2-container--theme-select2-standard .select2-selection--single {
  border-color: #E70A32 !important;
}
.form__select2-standard .js-form-input-error + .select2-container--theme-select2-standard .select2-selection__placeholder {
  color: #E70A32 !important;
}
.form__select2-standard .select2-container--open.select2-container--theme-select2-standard .select2-selection--single {
  border-bottom: 0;
}
.form__select2-standard .select2-container .select2-selection--single .select2-selection__clear {
  position: absolute;
  right: 50px;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #E70A32;
}
.form__select2-standard .select2-container--theme-select2-standard.select2-container--open {
  border-radius: 0;
}
.form__select2-standard .select2-dropdown {
  border: none;
}
.form__select2-standard .select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
}
.select2-container--theme-select2-standard.select2-container--open .select2-dropdown--below {
  border: 0;
  background: transparent;
}
.form__select2-standard .select2.select2-container {
  width: 100% !important;
}
.select2-dropdown {
  z-index: 1151;
}
.social-links__row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.social-links__col {
  padding-left: 5px;
  padding-right: 5px;
}
.social-links__item {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #92CD0C;
  transition: all 0.5s;
}
@media screen and (min-width: 1201px) {
  .social-links__item:hover {
    background: #111111;
  }
}
.breadcrumbs {
  padding-top: 20px;
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .breadcrumbs {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
@media screen and (max-width: 670px) {
  .breadcrumbs--not-mob {
    display: none;
  }
}
.breadcrumbs__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  font-size: 16px;
  line-height: 1.5;
  color: #767980;
  font-weight: 400;
}
@media screen and (max-width: 670px) {
  .breadcrumbs__list {
    font-size: 14px;
  }
}
.breadcrumbs__item:after {
  content: ' /  ';
  margin-right: 10px;
  margin-left: 10px;
}
@media screen and (max-width: 670px) {
  .breadcrumbs__item:after {
    margin-left: 5px;
    margin-right: 8px;
  }
}
.breadcrumbs__item:last-child:after {
  display: none;
}
.breadcrumbs__link {
  text-decoration: none;
}
@media screen and (min-width: 1201px) {
  .breadcrumbs__link:hover {
    text-decoration: underline;
  }
}
.breadcrumbs__span {
  font-weight: 400;
}
.fancybox-thumbs__list a::before {
  border-color: #E70A32;
  border-width: 4px;
}
.fancybox-navigation .fancybox-button--arrow_left {
  left: 50px;
}
@media screen and (max-width: 670px) {
  .fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
  }
}
.fancybox-navigation .fancybox-button--arrow_right {
  right: 50px;
}
@media screen and (max-width: 670px) {
  .fancybox-navigation .fancybox-button--arrow_right {
    right: 0;
  }
}
.fancybox-thumbs {
  background: rgba(30, 30, 30, 0.6);
}
.contacts {
  padding-bottom: 50px;
}
@media screen and (max-width: 670px) {
  .contacts {
    padding-bottom: 40px;
  }
}
.contacts__title {
  padding-bottom: 20px;
}
.contacts__widget {
  margin-bottom: 40px;
  border: 1px solid #E8E9ED;
  padding: 33px 60px 13px;
}
@media screen and (max-width: 1200px) {
  .contacts__widget {
    padding-left: 33px;
    padding-right: 33px;
  }
}
@media screen and (max-width: 670px) {
  .contacts__widget {
    margin-bottom: 20px;
    padding: 30px;
  }
}
.contacts__widget-row {
  justify-content: space-between;
}
.contacts__widget-col {
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .contacts__widget-col:last-child {
    padding-bottom: 0;
  }
}
.contacts__widget-item {
  display: flex;
  flex-wrap: wrap;
}
.contacts__widget-icon-wrap {
  width: 24px;
  font-size: 0;
}
.contacts__widget-info-wrap {
  max-width: calc(100% - 24px);
  padding-left: 10px;
}
.contacts__widget-item-name {
  color: #515456;
  font-size: 14px;
  line-height: 1.4;
}
.contacts__widget-item-value {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
}
.contacts__widget-item-value a {
  text-decoration: none;
}
.contacts__info-wrap {
  padding-bottom: 10px;
}
.contacts__info-title {
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
}
.contacts__info-item {
  padding-bottom: 10px;
}
.contacts__info-item-name {
  font-size: 14px;
  line-height: 1.4;
  color: #515456;
}
.contacts__info-item-value {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.contacts-map {
  position: relative;
  width: 100%;
  height: 400px;
  background: #cccccc;
}
@media screen and (max-width: 670px) {
  .contacts-map {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    height: 250px;
  }
}
.contacts-map__map {
  width: 100%;
  height: 100%;
}
ymaps[class$="search__suggest-item"] {
  font-family: 'Montserrat', sans-serif;
}
ymaps[class*="-balloon_layout_normal"] {
  transform: translateY(-20px) !important;
}
[class*="ymaps-2"][class*="-ground-pane"] {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
}
.contacts__map-ballon {
  font-family: 'Montserrat', sans-serif;
}
.contacts__map-adress-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #111111;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 5px;
}
.contacts__map-adress {
  font-size: 14px;
  line-height: 1.2;
  color: #111111;
  font-family: 'Montserrat', sans-serif;
}
.actions {
  padding-bottom: 50px;
}
@media screen and (max-width: 670px) {
  .actions {
    padding-bottom: 10px;
  }
}
.actions__title {
  padding-bottom: 30px;
}
@media screen and (max-width: 670px) {
  .actions__title {
    padding-bottom: 20px;
  }
}
.actions__item-wrap {
  padding-bottom: 30px;
}
.action-adv {
  display: block;
  text-decoration: none;
}
.action-adv__picture-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 71.2%;
  border-radius: 20px 20px 10px 10px;
  overflow: hidden;
}
@media screen and (max-width: 670px) {
  .action-adv__picture-wrap {
    padding-bottom: 58%;
    border-radius: 10px;
  }
}
.action-adv__picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.action-adv__text-wrap {
  padding-top: 20px;
}
.action-adv__title {
  padding-bottom: 13px;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .action-adv__title {
    font-size: 18px;
    padding-bottom: 10px;
  }
}
.action {
  padding-bottom: 80px;
}
@media screen and (max-width: 670px) {
  .action {
    padding-top: 8px;
    padding-bottom: 40px;
  }
}
.action__title {
  padding-bottom: 30px;
}
@media screen and (max-width: 670px) {
  .action__title {
    padding-bottom: 20px;
  }
}
.action__wrap {
  width: 100%;
  max-width: 950px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
  padding: 40px;
}
@media screen and (max-width: 670px) {
  .action__wrap {
    padding: 20px;
  }
}
.action__preview-picture {
  width: 100%;
  border-radius: 10px;
}
.action__text-wrap {
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (max-width: 670px) {
  .action__text-wrap {
    font-size: 14px;
    line-height: 1.4;
  }
}
.action__text-wrap h1,
.action__text-wrap h2,
.action__text-wrap h3,
.action__text-wrap img {
  padding-bottom: 20px;
}
.action__text-wrap p {
  padding-bottom: 20px;
}
.action__text-wrap p:last-child {
  padding-bottom: 0;
}
.action__text-wrap ul {
  list-style: none;
  padding-bottom: 15px;
}
.action__text-wrap ul li {
  padding-left: 20px;
  padding-bottom: 5px;
  position: relative;
}
.action__text-wrap ul li::before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #111111;
  position: absolute;
  top: 8px;
  left: 0;
}
.action__text-wrap ol {
  padding-bottom: 15px;
  list-style-position: inside;
}
.action__text-wrap ol li {
  padding-bottom: 5px;
}
.credite-page {
  padding-bottom: 40px;
}
.credite-page__form-wrap {
  box-shadow: 0px 10px 30px 0px rgba(14, 25, 35, 0.1);
  border-radius: 10px;
  background: #ffffff;
  padding: 25px 30px;
}
@media screen and (max-width: 1200px) {
  .credite-page__form-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.credite-info {
  padding-top: 10px;
  padding-bottom: 50px;
}
@media screen and (max-width: 670px) {
  .credite-info {
    padding-top: 4px;
    padding-bottom: 40px;
  }
}
.credite-info__preview-picture {
  width: 100%;
  border-radius: 20px;
}
@media screen and (max-width: 670px) {
  .credite-info__preview-picture {
    border-radius: 10px;
  }
}
.credite-info__text-wrap {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.credite-info__text-wrap h1,
.credite-info__text-wrap h2,
.credite-info__text-wrap h3,
.credite-info__text-wrap img {
  padding-bottom: 20px;
}
.credite-info__text-wrap p {
  padding-bottom: 20px;
}
.credite-info__text-wrap p:last-child {
  padding-bottom: 0;
}
.credite-info__text-wrap ul {
  list-style: none;
  padding-bottom: 15px;
}
.credite-info__text-wrap ul li {
  padding-left: 20px;
  padding-bottom: 5px;
  position: relative;
}
.credite-info__text-wrap ul li::before {
  content: '';
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #111111;
  position: absolute;
  top: 8px;
  left: 0;
}
.credite-info__text-wrap ol {
  padding-bottom: 15px;
  list-style-position: inside;
}
.credite-info__text-wrap ol li {
  padding-bottom: 5px;
}
.advantages {
  padding-bottom: 25px;
}
@media screen and (max-width: 670px) {
  .advantages {
    padding-bottom: 30px;
  }
}
.advantages__title {
  padding-bottom: 30px;
  font-size: 24px;
  line-height: 1.3;
}
.advantages__row {
  margin-left: -12px;
  margin-right: -12px;
}
.advantages__col {
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 25px;
}
@media screen and (max-width: 960px) {
  .advantages__col {
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 670px) {
  .advantages__col {
    padding-bottom: 10px;
  }
}
.advantages__item {
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  background: #ffffff;
  padding: 30px;
  height: 100%;
}
@media screen and (max-width: 670px) {
  .advantages__item {
    padding: 20px 30px;
  }
}
@media screen and (max-width: 1200px) {
  .advantages__item-wrap {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1200px) {
  .advantages__item-icon {
    width: 48px;
  }
}
.advantages__item-info {
  padding-top: 20px;
}
@media screen and (max-width: 1200px) {
  .advantages__item-info {
    padding-top: 0;
    width: calc(100% - 48px);
    padding-left: 20px;
  }
}
.advantages__item-name {
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
  width: 100%;
  max-width: 120px;
}
@media screen and (max-width: 1200px) {
  .advantages__item-name {
    max-width: 100%;
  }
}
.advantages__item-text {
  font-size: 14px;
  line-height: 1.4;
  color: #767980;
}
.partner-banks {
  padding-bottom: 60px;
}
@media screen and (max-width: 670px) {
  .partner-banks {
    padding-bottom: 20px;
  }
}
.partner-banks__title {
  padding-bottom: 30px;
  font-size: 24px;
  line-height: 1.3;
}
@media screen and (max-width: 670px) {
  .partner-banks__title {
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 670px) {
  .partner-banks__list-wrap {
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
  }
}
.partner-banks__row {
  margin-left: -8px;
  margin-right: -8px;
}
@media screen and (max-width: 670px) {
  .partner-banks__row {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
  .partner-banks__row::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.partner-banks__col {
  padding-bottom: 20px;
  padding-left: 8px;
  padding-right: 8px;
}
@media screen and (max-width: 670px) {
  .partner-banks__col {
    padding-top: 20px;
  }
}
.partner-banks__item {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
  border: 1px solid #F8F9FB;
  border-radius: 10px;
}
@media screen and (max-width: 670px) {
  .partner-banks__item {
    width: 146px;
    height: 60px;
  }
}
.credite-page-form__title {
  padding-bottom: 20px;
  font-size: 24px;
  line-height: 1.3;
  font-weight: 700;
  text-align: center;
}
.credite-page-form__car-info {
  padding-bottom: 10px;
  border-bottom: 1px solid #EFF2F4;
  margin-bottom: 20px;
}
.credite-page-form__item-input {
  padding-bottom: 10px;
}
.credite-page-form__subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
}
.credite-page-form__send-block {
  padding-top: 10px;
}
.credite-page-form__btn {
  width: 100%;
  font-size: 18px;
}
.credite-page-form__item-note {
  padding-top: 10px;
}
.credite-page-form__note {
  color: #767980;
  font-size: 12px;
  line-height: 1.3;
}
.credite-page-form__messange {
  font-size: 12px;
  width: 100%;
  padding-top: 10px;
  line-height: 1.3;
}
.promo {
  position: relative;
  z-index: 5;
}
.promo__picture {
  width: 100%;
  height: 450px;
  object-fit: cover;
}
@media screen and (max-width: 670px) {
  .promo__picture {
    height: auto;
    min-height: 540px;
  }
}
.promo__info-block {
  position: absolute;
  width: 100%;
  top: 48px;
  left: 0;
  right: 0;
}
@media screen and (max-width: 670px) {
  .promo__info-block {
    top: 50px;
  }
}
@media screen and (max-width: 350px) {
  .promo__info-block {
    top: 30px;
  }
}
.promo__title {
  color: #ffffff;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  padding-bottom: 60px;
}
@media screen and (max-width: 1200px) {
  .promo__title {
    font-size: 33px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .promo__title {
    font-size: 20px;
    line-height: 1.5;
    padding-bottom: 36px;
  }
  .promo__title br {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .promo__title {
    padding-bottom: 20px;
  }
}
.promo-filter {
  padding: 30px 30px 10px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 30px 0px rgba(14, 25, 35, 0.1);
}
@media screen and (max-width: 670px) {
  .promo-filter {
    padding: 20px 20px 10px;
  }
}
.promo-filter__item {
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .promo-filter__item {
    padding-bottom: 10px;
  }
}
.promo-filter__row {
  margin-left: -7px;
  margin-right: -7px;
  align-items: center;
}
.promo-filter__col {
  padding-left: 7px;
  padding-right: 7px;
  width: 19%;
}
@media screen and (max-width: 1200px) {
  .promo-filter__col {
    width: 33.33%;
  }
}
@media screen and (max-width: 670px) {
  .promo-filter__col {
    width: 100%;
  }
}
.promo-filter__col:nth-child(3) {
  width: 24%;
}
@media screen and (max-width: 1200px) {
  .promo-filter__col:nth-child(3) {
    width: 33.33%;
  }
}
@media screen and (max-width: 670px) {
  .promo-filter__col:nth-child(3) {
    width: 100%;
  }
}
.promo-filter__btn {
  width: 100%;
  font-size: 16px;
}
.promo-filter__input-price-wrap {
  position: relative;
  display: flex;
}
.promo-filter__input-price-item--from {
  width: 55%;
}
.promo-filter__input-price-item--from .form__input {
  border-radius: 10px 0 0 10px;
  border-right: 0;
}
.promo-filter__input-price-item--to {
  width: 45%;
}
.promo-filter__input-price-item--to .form__input {
  border-radius: 0 10px 10px 0;
}
.promo-filter__range-price {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}
.promo-filter__btn-more {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
}
@media screen and (max-width: 670px) {
  .promo-filter__btn-more {
    white-space: nowrap;
  }
}
.promo-filter__btn-more svg {
  width: 24px;
  transition: all 0.5s;
}
.promo-filter__btn-more span {
  color: #E70A32;
  font-size: 16px;
  line-height: 1.4;
  padding-right: 5px;
  max-width: calc(100% - 24px);
  white-space: nowrap;
}
.promo-filter__btn-more.js-filter-btn-all--open svg {
  transform: rotate(180deg);
}
.promo-filter__btn-reset {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
}
@media screen and (max-width: 670px) {
  .promo-filter__btn-reset {
    white-space: nowrap;
  }
}
.promo-filter__btn-reset svg {
  width: 20px;
  transition: all 0.5s;
}
.promo-filter__btn-reset span {
  color: #919395;
  font-size: 16px;
  line-height: 1.4;
  padding-left: 5px;
  max-width: calc(100% - 20px);
}
.promo-filter.js-filter-block--open .promo-filter__col {
  width: 25%;
}
@media screen and (max-width: 960px) {
  .promo-filter.js-filter-block--open .promo-filter__col {
    width: 33.33%;
  }
}
@media screen and (max-width: 670px) {
  .promo-filter.js-filter-block--open .promo-filter__col {
    width: 100%;
  }
}
.promo-filter.js-filter-block--open .promo-filter__col--btn-submit {
  order: 5;
  width: 245px;
  max-width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 670px) {
  .promo-filter.js-filter-block--open .promo-filter__col--btn-submit {
    width: 100%;
  }
}
.range {
  position: relative;
}
@media screen and (max-width: 1300px) {
  .range {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px);
  }
}
.range__value-item {
  position: relative;
}
.range__slider {
  width: 100%;
}
.range .noUi-target {
  background: #D9D9D9;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  height: 2px;
  cursor: pointer;
}
.range .noUi-connect {
  background: #E70A32;
}
.range .noUi-handle:before,
.range .noUi-handle:after {
  display: none;
}
.range .noUi-horizontal .noUi-handle {
  width: 14px;
  height: 14px;
  background: #E70A32;
  border-radius: 50%;
  cursor: pointer;
  border: 0;
  box-shadow: none;
  top: -6px;
  right: -7px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
}
.range .noUi-horizontal .noUi-tooltip {
  display: none;
}
.brands {
  padding-top: 60px;
  padding-bottom: 50px;
}
@media screen and (max-width: 670px) {
  .brands {
    padding-top: 40px;
    padding-bottom: 30px;
  }
}
.brands__title {
  font-size: 36px;
  line-height: 1.1;
  text-align: center;
  padding-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .brands__title {
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 1.3;
    text-align: left;
  }
}
@media screen and (max-width: 670px) {
  .brands__row {
    margin-left: -10px;
    margin-right: -10px;
  }
}
@media screen and (max-width: 670px) {
  .brands__col {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.brands__item-wrap {
  padding-bottom: 10px;
}
.brands__show-more-btn-wrap {
  padding-bottom: 10px;
}
.brands__show-more-btn {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  color: #E70A32;
  transition: all 0.5s;
}
.brands__show-more-btn span {
  padding-right: 5px;
}
.brands__show-more-btn svg {
  margin-top: 2px;
}
.brands__show-more-btn svg path {
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .brands__show-more-btn {
    height: 40px;
    border: 0;
    padding: 0;
  }
}
@media screen and (min-width: 1201px) {
  .brands__show-more-btn:hover {
    background: #E70A32;
    border-color: #E70A32;
    color: #ffffff;
  }
  .brands__show-more-btn:hover svg path {
    stroke: #ffffff !important;
  }
}
.brands__show-more-btn.js-hide-more-items-btn svg {
  transform: rotate(180deg);
}
.brand {
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
@media screen and (max-width: 670px) {
  .brand {
    height: 40px;
    border: 0;
    padding: 0;
  }
}
.brand__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.brand__info {
  display: flex;
  align-items: center;
}
.brand__logo-wrap {
  width: 40px;
  min-width: 40px;
}
@media screen and (max-width: 670px) {
  .brand__logo-wrap {
    width: 30px;
    min-width: 30px;
  }
}
.brand__logo {
  transition: all 0.5s;
  filter: grayscale(1);
}
.brand__name {
  padding-left: 10px;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .brand__name {
    padding-left: 5px;
    font-size: 14px;
  }
}
.brand__count {
  color: #919395;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
}
@media screen and (max-width: 670px) {
  .brand__count {
    font-size: 14px;
  }
}
.brand:hover .brand__logo {
  filter: none;
}
.brand:hover .brand__name {
  color: #E70A32;
}
.promo-cars {
  padding-bottom: 20px;
}
.promo-cars__title {
  font-size: 36px;
  line-height: 1.1;
  padding-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .promo-cars__title {
    font-size: 24px;
    padding-bottom: 20px;
  }
}
.promo-cars__item-wrap {
  padding-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .promo-cars__item-wrap {
    padding-bottom: 20px;
  }
}
.promo-cars--in-car {
  padding-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .promo-cars--in-car {
    padding-bottom: 30px;
  }
}
.promo-cars--in-car .promo-cars__title {
  font-size: 40px;
}
@media screen and (max-width: 670px) {
  .promo-cars--in-car .promo-cars__title {
    font-size: 24px;
  }
}
@media screen and (max-width: 670px) {
  .promo-car {
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
    padding: 15px;
  }
}
.promo-car__wrap {
  display: flex;
  flex-direction: column;
}
.promo-car__photo-block {
  display: block;
  text-decoration: none;
  position: relative;
}
@media screen and (max-width: 670px) {
  .promo-car__photo-block {
    order: 3;
  }
}
.promo-car__photo-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 67.6%;
  border-radius: 10px;
}
.promo-car__photo-wrap:before {
  content: '';
  display: block;
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #0D121C 1.67%, rgba(13, 18, 28, 0) 100%);
  opacity: 0.1;
  pointer-events: none;
  z-index: 5;
}
.promo-car__photo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: all 0.5s;
}
.promo-car__labels {
  position: absolute;
  width: 100%;
  top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
@media screen and (max-width: 670px) {
  .promo-car__labels {
    top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.promo-car__labels-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.promo-car__lablels-item {
  padding-left: 5px;
  padding-right: 5px;
}
.promo-car__label {
  display: flex;
  align-items: center;
  padding: 5px 12px 4px;
  border-radius: 100px;
  background: #ffffff;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #313335;
}
@media screen and (max-width: 670px) {
  .promo-car__label {
    font-size: 12px;
    line-height: 1.3;
  }
}
.promo-car__main-info-block {
  padding-top: 15px;
  padding-bottom: 10px;
}
@media screen and (max-width: 670px) {
  .promo-car__main-info-block {
    order: 1;
    padding-top: 0;
    padding-bottom: 0;
  }
}
.promo-car__main-info-wrap {
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 670px) {
}
.promo-car__name-wrap {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 670px) {
  .promo-car__name-wrap {
    font-size: 16px;
  }
}
.promo-car__name {
  text-decoration: none;
  transition: all 0.5s;
}
.promo-car__year {
  padding-left: 25px;
  position: relative;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
  color: #A2A5A8;
}
@media screen and (max-width: 670px) {
  .promo-car__year {
    padding-left: 8px;
    font-size: 14px;
  }
}
.promo-car__year::before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #A2A5A8;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
@media screen and (max-width: 670px) {
  .promo-car__year::before {
    display: none;
  }
}
.promo-car__text-block {
  padding-bottom: 15px;
  width: 100%;
  max-width: 350px;
}
@media screen and (max-width: 670px) {
  .promo-car__text-block {
    order: 4;
    padding-top: 15px;
    padding-bottom: 0;
    max-width: 100%;
  }
}
.promo-car__text {
  font-size: 16px;
  line-height: 1.5;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .promo-car__text {
    font-size: 14px;
    line-height: 1.4;
  }
}
@media screen and (max-width: 670px) {
  .promo-car__price-block {
    order: 2;
  }
}
.promo-car__price-wrap {
  padding: 15px 20px;
  background: #EFF2F4;
  border-radius: 10px;
}
@media screen and (max-width: 670px) {
  .promo-car__price-wrap {
    padding: 0;
    background: transparent;
    border-radius: 0;
  }
}
.promo-car__price {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
}
@media screen and (max-width: 670px) {
  .promo-car__price {
    margin-left: -5px;
    margin-right: -5px;
    align-items: center;
  }
}
.promo-car__price-item {
  padding-left: 7px;
  padding-right: 7px;
}
@media screen and (max-width: 670px) {
  .promo-car__price-item {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.promo-car__new-price {
  font-size: 24px;
  line-height: 1.3;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .promo-car__new-price {
    font-size: 18px;
    line-height: 1.5;
  }
}
.promo-car__old-price {
  text-decoration: line-through;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .promo-car__old-price {
    font-size: 12px;
    line-height: 1.5;
  }
}
.promo-car__credite {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .promo-car__credite {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
  }
}
.promo-car__credite-wrap {
  padding-top: 5px;
}
@media screen and (max-width: 670px) {
  .promo-car__credite-wrap {
    padding-top: 0;
    padding-bottom: 15px;
  }
}
.promo-car__credite-value {
  padding-left: 5px;
  color: #E70A32;
}
@media screen and (min-width: 1201px) {
  .promo-car:hover .promo-car__name {
    color: #E70A32;
  }
  .promo-car:hover .promo-car__photo {
    filter: saturate(300%);
  }
}
.promo-offer {
  padding-top: 60px;
  padding-bottom: 60px;
  background: #F2F5F7;
}
@media screen and (max-width: 670px) {
  .promo-offer {
    padding-top: 44px;
    padding-bottom: 30px;
  }
}
.promo-offer__wrap {
  padding: 50px 124px 50px;
  box-shadow: 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
  border-radius: 20px;
  background: #ffffff;
}
@media screen and (max-width: 1200px) {
  .promo-offer__wrap {
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media screen and (max-width: 670px) {
  .promo-offer__wrap {
    padding: 30px 20px 100px;
  }
}
.promo-offer__title {
  text-align: center;
  padding-bottom: 40px;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.1;
}
@media screen and (max-width: 960px) {
  .promo-offer__title {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 670px) {
  .promo-offer__title {
    padding-bottom: 20px;
    font-size: 24px;
    line-height: 1.3;
  }
}
.promo-offer__title span {
  color: #E70A32;
}
.slider-promo-cars {
  position: relative;
}
.slider-promo-cars__nav-btn {
  pointer-events: auto;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: all 0.5s;
  border-radius: 50%;
  border: 1px solid #E0E1E7;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__nav-btn {
    top: auto;
    transform: none;
    bottom: -70px;
    width: 40px;
    height: 40px;
  }
}
.slider-promo-cars__nav-btn svg path {
  transition: all 0.5s;
}
.slider-promo-cars__nav-btn--before {
  left: -89px;
}
@media screen and (max-width: 1200px) {
  .slider-promo-cars__nav-btn--before {
    left: -60px;
  }
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__nav-btn--before {
    left: calc(50% - 50px);
  }
}
.slider-promo-cars__nav-btn--after {
  right: -89px;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 1200px) {
  .slider-promo-cars__nav-btn--after {
    right: -60px;
  }
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__nav-btn--after {
    right: calc(50% - 50px);
    transform: rotate(180deg);
  }
}
@media screen and (min-width: 1201px) {
  .slider-promo-cars__nav-btn:hover svg path {
    stroke: #E70A32;
  }
}
.slider-promo-cars__dots {
  position: absolute;
  bottom: 62px;
  left: 10px;
  right: 10px;
  margin: auto;
}
@media screen and (max-width: 960px) {
  .slider-promo-cars__dots {
    bottom: auto;
    top: 260px;
    width: calc(100% - 20px);
  }
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__dots {
    bottom: auto;
    top: 234px;
    width: calc(100% - 20px);
  }
}
.slider-promo-cars__dots .slick-dots {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  list-style: none;
  pointer-events: auto;
  margin-left: -3px;
  margin-right: -3px;
}
@media screen and (max-width: 960px) {
  .slider-promo-cars__dots .slick-dots {
    justify-content: center;
  }
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__dots .slick-dots {
    margin-left: -3px;
    margin-right: -3px;
  }
}
.slider-promo-cars__dots .slick-dots li {
  line-height: 0;
  font-size: 0;
  margin: 0;
  padding: 0 3px;
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__dots .slick-dots li {
    padding: 0 3px;
    flex-grow: 1;
  }
}
.slider-promo-cars__dots .slick-dots button {
  font-size: 0;
  width: 66px;
  height: 4px;
  border-radius: 2px;
  background: #FFFFFF;
  position: relative;
  transition: all 0.2s;
  opacity: 0.4;
  border-radius: 20px;
}
@media screen and (max-width: 670px) {
  .slider-promo-cars__dots .slick-dots button {
    width: 100%;
  }
}
@media screen and (min-width: 1201px) {
  .slider-promo-cars__dots .slick-dots button:hover {
    opacity: 1;
  }
}
.slider-promo-cars__dots .slick-dots .slick-active button {
  opacity: 1;
}
.offer-car__row {
  align-items: center;
}
.offer-car__col--photo {
  width: 61%;
}
@media screen and (max-width: 1200px) {
  .offer-car__col--photo {
    width: 55%;
  }
}
@media screen and (max-width: 960px) {
  .offer-car__col--photo {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .offer-car__col--photo {
    width: 100%;
  }
}
.offer-car__col--info {
  width: 39%;
}
@media screen and (max-width: 1200px) {
  .offer-car__col--info {
    width: 45%;
  }
}
@media screen and (max-width: 960px) {
  .offer-car__col--info {
    width: 100%;
  }
}
@media screen and (max-width: 670px) {
  .offer-car__col--info {
    width: 100%;
  }
}
.offer-car__photo-block {
  display: block;
  text-decoration: none;
  position: relative;
}
.offer-car__photo-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}
.offer-car__photo {
  object-fit: cover;
  transition: all 0.5s;
  width: 100%;
  height: 300px;
}
@media screen and (max-width: 670px) {
  .offer-car__photo {
    height: 250px;
  }
}
.offer-car__labels {
  position: absolute;
  width: 100%;
  top: 15px;
  padding-left: 15px;
  padding-right: 15px;
}
.offer-car__labels-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.offer-car__lablels-item {
  padding-left: 5px;
  padding-right: 5px;
}
.offer-car__label {
  display: flex;
  align-items: center;
  padding: 5px 12px 4px;
  border-radius: 100px;
  background: #ffffff;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 500;
  color: #313335;
}
.offer-car__info-block {
  width: 100%;
  max-width: 341px;
}
@media screen and (max-width: 1200px) {
  .offer-car__info-block {
    max-width: 100%;
  }
}
@media screen and (max-width: 960px) {
  .offer-car__info-block {
    padding-top: 15px;
  }
}
@media screen and (max-width: 670px) {
  .offer-car__info-block {
    max-width: 100%;
    padding-top: 15px;
  }
}
.offer-car__main-info-block {
  padding-bottom: 10px;
}
.offer-car__main-info-wrap {
  display: flex;
  align-items: flex-end;
}
.offer-car__name-wrap {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
}
.offer-car__name {
  text-decoration: none;
  transition: all 0.5s;
}
.offer-car__year {
  padding-left: 25px;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.6;
  color: #A2A5A8;
}
.offer-car__year::before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #A2A5A8;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.offer-car__text-block {
  padding-bottom: 15px;
  width: 100%;
}
@media screen and (max-width: 670px) {
  .offer-car__text-block {
    padding-bottom: 10px;
  }
}
.offer-car__text {
  font-size: 18px;
  line-height: 1.5;
  color: #767980;
}
.offer-car__price-block {
  padding-bottom: 15px;
}
.offer-car__price-wrap {
  padding: 20px 30px;
  background: #EFF2F4;
  border-radius: 10px;
}
@media screen and (max-width: 670px) {
  .offer-car__price-wrap {
    padding: 10px 20px;
  }
}
.offer-car__price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -7px;
  margin-right: -7px;
}
.offer-car__price-item {
  padding-left: 7px;
  padding-right: 7px;
}
.offer-car__new-price {
  font-size: 28px;
  line-height: 1.3;
  font-weight: 700;
  color: #E70A32;
}
@media screen and (max-width: 670px) {
  .offer-car__new-price {
    font-size: 24px;
  }
}
.offer-car__old-price {
  text-decoration: line-through;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .offer-car__old-price {
    font-size: 16px;
  }
}
.offer-car__credite {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #767980;
}
.offer-car__credite-wrap {
  padding-top: 5px;
}
.offer-car__credite-value {
  padding-left: 5px;
  color: #E70A32;
}
.offer-car__btn {
  width: 100%;
}
@media screen and (min-width: 1201px) {
  .offer-car:hover .offer-car__name {
    color: #E70A32;
  }
}
.promo-privileges {
  background: #F2F5F7;
  padding-bottom: 40px;
}
@media screen and (max-width: 670px) {
  .promo-privileges {
    padding-bottom: 20px;
  }
}
.promo-privileges__row {
  margin-left: -10px;
  margin-right: -10px;
  justify-content: center;
}
.promo-privileges__col {
  padding-left: 10px;
  padding-right: 10px;
}
.promo-privileges__item-wrap {
  padding-bottom: 20px;
}
.privilege {
  width: 100%;
  height: 220px;
  border-radius: 20px;
  background: #ffffff;
  padding: 30px;
  position: relative;
  display: block;
  text-decoration: none;
}
.privilege__info-wrap {
  position: relative;
  z-index: 5;
}
.privilege__name {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  padding-bottom: 10px;
  width: 100%;
  max-width: 230px;
}
.privilege__picture-wrap {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 60%;
  max-width: 100%;
}
.privilege--more-cars .privilege__picture-wrap {
  bottom: 7px;
  width: 209px;
}
.privilege--car-exchange .privilege__picture-wrap {
  bottom: 10px;
  width: 174px;
}
.privilege--credite .privilege__picture-wrap {
  right: 7px;
  bottom: 5px;
  width: 163px;
}
@media screen and (max-width: 1200px) {
  .privilege--credite .privilege__picture-wrap {
    right: 0;
    bottom: 0;
  }
}
.catalog-page {
  padding-bottom: 60px;
  background: #F8FAFB;
}
@media screen and (max-width: 670px) {
  .catalog-page {
    padding-bottom: 30px;
  }
}
.catalog-page__row {
  margin-left: -10px;
  margin-right: -10px;
}
.catalog-page__col {
  padding-left: 10px;
  padding-right: 10px;
}
.catalog-page__title {
  padding-bottom: 23px;
  font-size: 40px;
  line-height: 1.2;
}
@media screen and (max-width: 1200px) {
  .catalog-page__title {
    font-size: 36px;
  }
}
@media screen and (max-width: 670px) {
  .catalog-page__title {
    font-size: 24px;
    padding-bottom: 20px;
    line-height: 1.3;
  }
}
.catalog-page__filter-wrap {
  padding-bottom: 30px;
}
.catalog-filter {
  padding: 20px 20px 0;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 30px 0px rgba(14, 25, 35, 0.1);
}
@media screen and (max-width: 670px) {
  .catalog-filter {
    padding: 25px 20px 10px;
  }
}
.catalog-filter__item {
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .catalog-filter__item {
    padding-bottom: 15px;
  }
}
.catalog-filter__row {
  margin-left: -7px;
  margin-right: -7px;
  align-items: center;
}
.catalog-filter__col {
  padding-left: 7px;
  padding-right: 7px;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .catalog-filter__col {
    width: 100%;
  }
}
.catalog-filter__col--btn-submit {
  margin-left: auto;
}
@media screen and (max-width: 670px) {
  .catalog-filter__col--btn-all,
  .catalog-filter__col--btn-reset {
    order: 3;
    text-align: center;
  }
}
.catalog-filter__btn {
  width: 100%;
  font-size: 16px;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .catalog-filter__btn {
    font-weight: 500;
    font-size: 15px;
  }
}
.catalog-filter__input-price-wrap {
  position: relative;
  display: flex;
}
.catalog-filter__input-price-item--from {
  width: 55%;
}
.catalog-filter__input-price-item--from .form__input {
  border-radius: 10px 0 0 10px;
  border-right: 0;
}
.catalog-filter__input-price-item--to {
  width: 45%;
}
.catalog-filter__input-price-item--to .form__input {
  border-radius: 0 10px 10px 0;
}
.catalog-filter__range-price {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}
.catalog-filter__btn-more {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
}
@media screen and (max-width: 670px) {
  .catalog-filter__btn-more {
    white-space: nowrap;
  }
}
.catalog-filter__btn-more svg {
  width: 24px;
  transition: all 0.5s;
}
.catalog-filter__btn-more span {
  color: #E70A32;
  font-size: 16px;
  line-height: 1.4;
  padding-right: 5px;
  max-width: calc(100% - 24px);
  padding-top: 2px;
  white-space: nowrap;
}
.catalog-filter__btn-more.js-filter-btn-all--open svg {
  transform: rotate(180deg);
}
.catalog-filter__btn-reset {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0;
  margin-left: 10px;
}
@media screen and (max-width: 670px) {
  .catalog-filter__btn-reset {
    white-space: nowrap;
    margin-left: 0;
  }
}
.catalog-filter__btn-reset svg {
  width: 20px;
  transition: all 0.5s;
}
.catalog-filter__btn-reset span {
  color: #919395;
  font-size: 16px;
  line-height: 1.4;
  padding-left: 5px;
  max-width: calc(100% - 20px);
  padding-top: 2px;
}
@media screen and (max-width: 670px) {
  .js-filter-mob-item-toggle {
    display: none;
  }
}
@media screen and (max-width: 670px) {
  .catalog-filter.js-filter-block--open .js-filter-mob-item-toggle {
    display: block;
  }
}
.catalog-filter.js-filter-block--open .catalog-filter__col--btn-submit {
  max-width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 670px) {
  .catalog-filter.js-filter-block--open .catalog-filter__col--btn-submit {
    width: 100%;
  }
}
.widget-privileges__row {
  justify-content: center;
}
.widget-privileges__item-wrap {
  padding-bottom: 20px;
}
@media screen and (max-width: 1200px) {
  .catalog {
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 670px) {
  .catalog {
    padding-bottom: 50px;
  }
}
.catalog__working-block {
  padding-bottom: 30px;
}
@media screen and (max-width: 670px) {
  .catalog__working-block {
    padding-bottom: 20px;
  }
}
.catalog__working-row {
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width: 670px) {
  .catalog__working-col {
    width: auto;
  }
}
.catalog__count {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (max-width: 670px) {
  .catalog__count {
    font-size: 15px;
  }
}
.catalog__wrap {
  padding-bottom: 20px;
}
.catalog__item {
  padding-bottom: 10px;
}
.catalog__for-select {
  width: 200px;
  max-width: 100%;
}
@media screen and (max-width: 670px) {
  .catalog__for-select {
    width: 170px;
  }
}
.catalog__for-select:before {
  background-color: #ffffff;
}
.catalog__select {
  background: #ffffff;
  color: #313335;
  font-weight: 400;
}
@media screen and (max-width: 670px) {
  .catalog__select {
    height: 40px;
    font-size: 14px;
    font-weight: 500;
  }
}
.catalog__select option {
  font-size: 14px;
}
.catalog-car {
  padding: 15px;
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
}
@media screen and (max-width: 670px) {
  .catalog-car {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.catalog-car__row {
  margin-left: 0;
  margin-right: 0;
}
@media screen and (max-width: 670px) {
  .catalog-car__row {
    margin-left: 0;
    margin-right: 0;
  }
}
.catalog-car__col {
  padding-left: 0;
  padding-right: 0;
}
@media screen and (max-width: 670px) {
  .catalog-car__col {
    padding-left: 0;
    padding-right: 0;
  }
}
.catalog-car__col--photo {
  width: 240px;
}
@media screen and (max-width: 670px) {
  .catalog-car__col--photo {
    width: 100%;
  }
}
.catalog-car__col--info {
  width: calc(100% - 240px - 40px);
  padding-left: 14px;
  padding-right: 20px;
}
@media screen and (max-width: 670px) {
  .catalog-car__col--info {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    order: -1;
  }
}
.catalog-car__col--mob-info {
  display: none;
}
@media screen and (max-width: 670px) {
  .catalog-car__col--mob-info {
    display: block;
    width: 100%;
  }
}
.catalog-car__col--btns {
  width: 40px;
}
@media screen and (max-width: 670px) {
  .catalog-car__col--btns {
    display: none;
    width: 100%;
  }
}
.catalog-car__photo-block {
  display: block;
  text-decoration: none;
  position: relative;
}
.catalog-car__photo-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 10px;
}
@media screen and (max-width: 670px) {
  .catalog-car__photo-wrap {
    border-radius: 0;
  }
}
.catalog-car__photo {
  object-fit: cover;
  transition: all 0.5s;
  width: 100%;
  height: 180px;
}
@media screen and (max-width: 670px) {
  .catalog-car__photo {
    width: 243px;
    height: 200px;
    border-radius: 10px;
  }
}
.catalog-car__labels {
  position: absolute;
  width: 100%;
  top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.catalog-car__labels-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
}
.catalog-car__lablels-item {
  padding-left: 5px;
  padding-right: 5px;
}
.catalog-car__label {
  display: flex;
  align-items: center;
  padding: 5px 12px 4px;
  border-radius: 100px;
  background: #ffffff;
  font-size: 12px;
  line-height: 1.3;
  font-weight: 500;
  color: #313335;
}
.catalog-car__main-info {
  padding-bottom: 15px;
}
.catalog-car__main-info-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.catalog-car__name-wrap {
  padding-bottom: 5px;
}
@media screen and (max-width: 670px) {
  .catalog-car__name-wrap {
    padding-bottom: 0;
  }
}
.catalog-car__name {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: #E70A32;
  text-decoration: none;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .catalog-car__name {
    color: #111111;
  }
}
@media screen and (min-width: 1201px) {
  .catalog-car__name:hover {
    text-decoration: underline;
  }
}
.catalog-car__price {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
}
.catalog-car__price-item {
  padding-left: 5px;
  padding-right: 5px;
}
.catalog-car__new-price {
  font-size: 18px;
  line-height: 1.5;
  font-weight: 700;
}
.catalog-car__old-price {
  text-decoration: line-through;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #767980;
}
.catalog-car__credite-wrap {
  border: 1px solid #E0E1E7;
  border-radius: 5px;
  background: #ffffff;
  padding: 10px 15px 8px;
}
.catalog-car__credite-item {
  font-size: 14px;
  line-height: 1.4;
  color: #515456;
}
@media screen and (max-width: 670px) {
  .catalog-car__credite-item {
    font-size: 12px;
    line-height: 1.3;
  }
}
.catalog-car__credite-value {
  color: #E70A32;
}
@media screen and (max-width: 670px) {
  .catalog-car__text-wrap {
    display: none;
  }
}
.catalog-car__text-item {
  padding-bottom: 15px;
  font-size: 14px;
  line-height: 1.4;
  color: #767980;
}
.catalog-car__text-item:last-child {
  padding-bottom: 0;
}
@media screen and (max-width: 670px) {
  .catalog-car__mob-text-wrap {
    padding-top: 15px;
  }
}
@media screen and (max-width: 670px) {
  .catalog-car__mob-text {
    font-size: 14px;
    line-height: 1.4;
    color: #767980;
  }
}
.catalog-car__btns-block {
  display: block;
}
.catalog-car__btns-item {
  padding-bottom: 10px;
}
.catalog-car__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  font-size: 0;
  transition: all 0.5s;
}
.catalog-car__btn--red {
  background: #E70A32;
}
@media screen and (min-width: 1201px) {
  .catalog-car__btn--red:hover {
    background: #c1082a;
  }
}
.catalog-car__btn--green {
  background: #0EA348;
}
@media screen and (min-width: 1201px) {
  .catalog-car__btn--green:hover {
    background: #0b7f38;
  }
}
.pagination__list {
  list-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-left: -5px;
  margin-right: -5px;
}
.pagination__item {
  padding-left: 5px;
  padding-right: 5px;
}
.pagination__item--prev {
  padding-right: 15px;
}
.pagination__item--next {
  padding-left: 15px;
}
.pagination__link {
  width: 36px;
  height: 36px;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 0.5s;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 8px;
  background: #ffffff;
  color: #313335;
  padding-top: 2px;
}
.pagination__link svg path {
  transition: all 0.5s;
}
@media screen and (min-width: 1201px) {
  .pagination__link:hover {
    background: #767980;
    color: #ffffff;
  }
  .pagination__link:hover svg path {
    stroke: #ffffff;
  }
}
.pagination__link--active {
  background: #E70A32;
  color: #ffffff;
}
.pagination__link--page-prev,
.pagination__link--page-next {
  border: 1px solid #E0E1E7;
}
span.pagination__link {
  opacity: 0.5;
  pointer-events: none;
}
.slider-photo-catalog-car {
  position: relative;
}
@media screen and (max-width: 670px) {
  .slider-photo-catalog-car .slick-list {
    margin-left: -5px;
    margin-right: -5px;
  }
}
@media screen and (max-width: 670px) {
  .slider-photo-catalog-car .slider-photo-catalog-car__item {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.slider-photo-catalog-car__dots {
  position: absolute;
  bottom: 15px;
  left: 25px;
  right: 25px;
  margin: auto;
  width: calc(100% - 50px);
}
@media screen and (max-width: 960px) {
  .slider-photo-catalog-car__dots {
    width: calc(100% - 20px);
  }
}
@media screen and (max-width: 670px) {
  .slider-photo-catalog-car__dots {
    display: none;
  }
}
.slider-photo-catalog-car__dots .slick-dots {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  list-style: none;
  pointer-events: auto;
  margin-left: -5px;
  margin-right: -5px;
}
.slider-photo-catalog-car__dots .slick-dots li {
  line-height: 0;
  font-size: 0;
  margin: 0;
  padding: 0 5px;
  flex-grow: 1;
}
.slider-photo-catalog-car__dots .slick-dots button {
  font-size: 0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background: #FFFFFF;
  position: relative;
  transition: all 0.2s;
  opacity: 0.4;
  border-radius: 10px;
}
@media screen and (min-width: 1201px) {
  .slider-photo-catalog-car__dots .slick-dots button:hover {
    opacity: 1;
  }
}
.slider-photo-catalog-car__dots .slick-dots .slick-active button {
  opacity: 1;
}
.car-page {
  padding-bottom: 60px;
}
@media screen and (max-width: 670px) {
  .car-page {
    padding-top: 20px;
    padding-bottom: 25px;
  }
}
@media screen and (max-width: 670px) {
  .car-page__col--credite {
    display: none;
  }
}
.car-page__name-block {
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .car-page__name-block {
    padding-bottom: 0;
  }
}
.car-page__name-info-wrap {
  display: flex;
  align-items: center;
}
.car-page__name {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.3;
}
@media screen and (max-width: 670px) {
  .car-page__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
  }
}
.car-page__year {
  padding-left: 25px;
  position: relative;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.3;
  color: #A2A5A8;
}
@media screen and (max-width: 670px) {
  .car-page__year {
    font-size: 16px;
    line-height: 1.5;
  }
}
.car-page__year::before {
  content: '';
  display: block;
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #A2A5A8;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}
.car-page__price-wrap {
  padding-bottom: 20px;
}
.car-page__price {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-left: -7px;
  margin-right: -7px;
}
.car-page__price-item {
  padding-left: 7px;
  padding-right: 7px;
}
.car-page__new-price {
  font-size: 32px;
  line-height: 1.3;
  font-weight: 700;
}
@media screen and (max-width: 670px) {
  .car-page__new-price {
    font-size: 24px;
  }
}
.car-page__old-price {
  text-decoration: line-through;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .car-page__old-price {
    font-size: 16px;
  }
}
.car-page__photogallery-wrap {
  padding-bottom: 20px;
}
.car-page__specification-wrap {
  padding-top: 10px;
}
.car-page__btn-call-wrap {
  padding-bottom: 20px;
}
@media screen and (max-width: 960px) {
  .car-page__btn-call-wrap {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
@media screen and (max-width: 670px) {
  .car-page__btn-call-wrap {
    display: none;
  }
}
.car-page__btn-call {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
  border: 1px solid #E0E1E7;
  border-radius: 10px;
  text-decoration: none;
  transition: all 0.5s;
  background: #ffffff;
}
.car-page__btn-call-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.car-page__btn-call-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E70A32;
  border-radius: 50%;
  transition: all 0.5s;
}
.car-page__btn-call-icon svg path {
  transition: all 0.5s;
}
.car-page__btn-call-text {
  max-width: calc(100% - 36px);
  padding-left: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #E70A32;
  transition: all 0.5s;
}
@media screen and (max-width: 670px) {
  .car-page__legal-info {
    padding-top: 25px;
  }
}
.car-page__legal-title {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
}
@media screen and (max-width: 670px) {
  .car-page__legal-title {
    font-size: 18px;
  }
}
.car-page__legal-list {
  list-style: none;
}
.car-page__legal-list li {
  position: relative;
  min-height: 32px;
  padding-bottom: 15px;
  padding-left: 47px;
  font-size: 16px;
  line-height: 1.5;
  color: #313335;
  font-weight: 500;
  padding-top: 2px;
}
@media screen and (max-width: 670px) {
  .car-page__legal-list li {
    font-size: 14px;
  }
}
.car-page__legal-list li::before {
  content: '';
  display: block;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/icons/checkmark.svg) no-repeat center;
  background-size: contain;
}
@media screen and (min-width: 1201px) {
  .car-page__btn-call:hover {
    background: #E70A32;
    border-color: #E70A32;
  }
  .car-page__btn-call:hover .car-page__btn-call-icon {
    background: #ffffff;
  }
  .car-page__btn-call:hover .car-page__btn-call-icon svg path {
    stroke: #E70A32;
  }
  .car-page__btn-call:hover .car-page__btn-call-text {
    color: #ffffff;
  }
}
.car-photo {
  position: relative;
}
@media screen and (max-width: 670px) {
  .car-photo {
    margin-left: -15px;
    margin-right: -15px;
  }
}
.car-photo__item-thumbs span {
  display: none;
}
.car-photo__item-thumbs--btn {
  position: relative;
}
.car-photo__item-thumbs--btn span {
  display: flex;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 5px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
}
.car-photo__photogallery-wrap {
  position: relative;
}
.car-photo__photo-wrap {
  width: auto;
  width: 100%;
  position: relative;
}
.car-photo__photo-wrap a {
  display: block;
  height: 500px;
  width: 100%;
}
@media screen and (max-width: 670px) {
  .car-photo__photo-wrap a {
    height: 300px;
  }
}
.car-photo__photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
@media screen and (max-width: 670px) {
  .car-photo__photo {
    border-radius: 0;
  }
}
.car-photo__for-thumbs {
  overflow: hidden;
  padding-top: 20px;
}
@media screen and (max-width: 670px) {
  .car-photo__for-thumbs {
    display: none;
  }
}
.car-photo__thumbs .slick-track:before,
.car-photo__thumbs .slick-track:after {
  display: none;
}
.car-photo__thumbs .slick-track {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}
.car-photo__thumbs .slick-list {
  margin-left: -5px;
  margin-right: -5px;
}
.car-photo__thumbs .car-photo__item-thumbs {
  padding-left: 5px;
  padding-right: 5px;
}
.car-photo__thumbs-photo-wrap {
  width: 145px;
  height: 90px;
  position: relative;
  cursor: pointer;
  background: #FAFAFA;
  margin-bottom: 10px;
}
.car-photo__thumbs-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.car-photo__thumbs .car-photo__item-thumbs.slick-current .car-photo__thumbs-photo {
  border: 2px solid #E70A32;
}
.car-photo__nav-btn {
  pointer-events: auto;
  display: inline-flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  transition: all 0.5s;
  border-radius: 50%;
  background: #ffffff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
@media screen and (max-width: 670px) {
  .car-photo__nav-btn {
    width: 40px;
    height: 40px;
  }
}
.car-photo__nav-btn svg path {
  transition: all 0.5s;
  stroke: #767980;
}
.car-photo__nav-btn--before {
  left: 30px;
}
@media screen and (max-width: 670px) {
  .car-photo__nav-btn--before {
    left: 15px;
  }
}
.car-photo__nav-btn--after {
  right: 30px;
  transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 670px) {
  .car-photo__nav-btn--after {
    right: 15px;
  }
}
@media screen and (min-width: 1201px) {
  .car-photo__nav-btn:hover svg path {
    stroke: #E70A32;
  }
}
.car-photo__dots {
  position: absolute;
  bottom: 15px;
  left: 25px;
  right: 25px;
  margin: auto;
  width: calc(100% - 50px);
}
@media screen and (max-width: 960px) {
  .car-photo__dots {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
  }
}
.car-photo__dots .slick-dots {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  list-style: none;
  pointer-events: auto;
  margin-left: -5px;
  margin-right: -5px;
}
@media screen and (max-width: 670px) {
  .car-photo__dots .slick-dots {
    margin-left: -2px;
    margin-right: -2px;
  }
}
.car-photo__dots .slick-dots li {
  line-height: 0;
  font-size: 0;
  margin: 0;
  padding: 0 5px;
  flex-grow: 1;
}
@media screen and (max-width: 670px) {
  .car-photo__dots .slick-dots li {
    padding: 0 2px;
  }
}
.car-photo__dots .slick-dots button {
  font-size: 0;
  width: 100%;
  height: 3px;
  border-radius: 2px;
  background: #FFFFFF;
  position: relative;
  transition: all 0.2s;
  opacity: 0.4;
  border-radius: 10px;
}
@media screen and (min-width: 1201px) {
  .car-photo__dots .slick-dots button:hover {
    opacity: 1;
  }
}
.car-photo__dots .slick-dots .slick-active button {
  opacity: 1;
  background: #E70A32;
}
.credite-single-car {
  box-shadow: 0px 10px 30px 0px rgba(14, 25, 35, 0.1);
  border-radius: 10px;
  background: #ffffff;
  padding: 25px 30px;
}
@media screen and (max-width: 1200px) {
  .credite-single-car {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.credite-single-car__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
}
.credite-single-car__car-info {
  padding-bottom: 10px;
  border-bottom: 1px solid #EFF2F4;
  margin-bottom: 20px;
}
.credite-single-car__item-input {
  padding-bottom: 10px;
}
.credite-single-car__subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  padding-bottom: 10px;
}
.credite-single-car__send-block {
  padding-top: 10px;
}
.credite-single-car__btn {
  width: 100%;
  font-size: 18px;
}
.credite-single-car__item-note {
  padding-top: 10px;
}
.credite-single-car__note {
  color: #767980;
  font-size: 12px;
  line-height: 1.3;
}
.credite-single-car__messange {
  font-size: 12px;
  width: 100%;
  padding-top: 10px;
  line-height: 1.3;
}
.credite-single-car__input-range-wrap {
  position: relative;
}
.credite-single-car__range-price {
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
}
.credite-single-car__input {
  text-align: center;
}
.credite-single-car__label {
  padding-bottom: 5px;
  font-size: 14px;
  line-height: 1.4;
  color: #515456;
}
.credite-single-car__result-wrap {
  padding-top: 10px;
}
.credite-single-car__result-item-wrap {
  padding-bottom: 10px;
}
.credite-single-car__result-item {
  display: flex;
}
.credite-single-car__result-name {
  width: 190px;
  font-size: 16px;
  line-height: 1.5;
  color: #313335;
}
.credite-single-car__result-value {
  width: calc(100% - 190px);
  padding-left: 20px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.car-specification__tab-nav-list {
  display: flex;
  border-bottom: 1px solid #E0E1E7;
}
.car-specification__tab-nav-item {
  padding-right: 40px;
}
@media screen and (max-width: 670px) {
  .car-specification__tab-nav-item {
    padding-right: 15px;
  }
}
@media screen and (max-width: 350px) {
  .car-specification__tab-nav-item {
    padding-right: 7px;
  }
}
.car-specification__tab-nav-item:last-child {
  padding-right: 0;
}
@media screen and (max-width: 670px) {
  .car-specification__tab-nav-item:last-child {
    padding-right: 0;
  }
}
.car-specification__tab-btn {
  display: flex;
  padding-top: 18px;
  padding-bottom: 14px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  color: #515456;
  position: relative;
}
@media screen and (max-width: 670px) {
  .car-specification__tab-btn {
    padding-top: 0;
    padding-bottom: 16px;
    font-size: 14px;
  }
}
@media screen and (max-width: 350px) {
  .car-specification__tab-btn {
    font-size: 12px;
  }
}
.car-specification__tab-btn::after {
  content: '';
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: -2px;
  background: #E70A32;
  transition: all 0.5s;
  opacity: 0;
}
.car-specification__tab-btn.js-tabs-btn--active {
  color: #E70A32;
}
.car-specification__tab-btn.js-tabs-btn--active:after {
  opacity: 1;
}
.car-specification__tabs-info-wrap {
  padding-top: 40px;
}
@media screen and (max-width: 670px) {
  .car-specification__tabs-info-wrap {
    padding-top: 20px;
  }
}
.car-specification__title {
  font-size: 28px;
  line-height: 1.3;
  font-weight: 700;
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .car-specification__title {
    font-size: 20px;
  }
}
.car-specification__options-list {
  list-style: none;
  columns: 3 auto;
  column-gap: 30px;
}
@media screen and (max-width: 670px) {
  .car-specification__options-list {
    columns: 2 auto;
  }
}
.car-specification__options-item {
  display: inline-block;
  padding-bottom: 20px;
  width: 100%;
}
@media screen and (max-width: 670px) {
  .car-specification__options-item {
    padding-bottom: 15px;
  }
}
.car-specification__option-name {
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: #767980;
  padding-bottom: 5px;
}
@media screen and (max-width: 670px) {
  .car-specification__option-name {
    font-size: 14px;
  }
}
.car-specification__option-value {
  display: block;
  font-size: 18px;
  line-height: 1.5;
  color: #313335;
  font-weight: 500;
}
@media screen and (max-width: 670px) {
  .car-specification__option-value {
    font-size: 16px;
  }
}
.car-specification__description p {
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 10px;
  color: #767980;
}
@media screen and (max-width: 670px) {
  .car-specification__description p {
    font-size: 14px;
  }
}
.car-specification__equipment p {
  font-size: 16px;
  line-height: 1.5;
  color: #313335;
  font-weight: 500;
  padding-bottom: 10px;
}
.car-specification__equipment ul {
  list-style: none;
  columns: 3 auto;
  column-gap: 30px;
  padding-bottom: 20px;
}
@media screen and (max-width: 670px) {
  .car-specification__equipment ul {
    columns: initial;
  }
}
.car-specification__equipment li {
  display: inline-block;
  width: 100%;
  font-size: 12px;
  line-height: 1.3;
  color: #767980;
  padding-bottom: 5px;
}
.car-mob-widget {
  display: none;
}
@media screen and (max-width: 670px) {
  .car-mob-widget {
    display: block;
    padding: 15px;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background: #ffffff;
    box-shadow: 0px 0px 30px 0px rgba(14, 25, 35, 0.12);
  }
}
.car-mob-widget__row {
  margin-left: -7px;
  margin-right: -7px;
}
.car-mob-widget__col {
  padding-left: 7px;
  padding-right: 7px;
}
.car-mob-widget__col--credite {
  width: calc(100% - 74px);
}
.car-mob-widget__col--call {
  width: 74px;
}
.car-mob-widget__btn-credite {
  display: inline-flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 60px;
  color: #ffffff;
  text-align: center;
  background: #E70A32;
  text-decoration: none;
  padding-bottom: 3px;
}
.car-mob-widget__btn-credite-name {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
}
.car-mob-widget__btn-credite-value {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  padding-top: 3px;
}
.car-mob-widget__btn-call {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #0EA348;
  text-decoration: none;
}
