.promo-cars {
  padding-bottom: 20px;

  &__title {
    font-size     : 36px;
    line-height   : 1.1;
    padding-bottom: 40px;

    @media screen and (max-width: @mob) {
      font-size     : 24px;
      padding-bottom: 20px;
    }
  }

  &__item-wrap {
    padding-bottom: 40px;

    @media screen and (max-width: @mob) {
      padding-bottom: 20px;
    }
  }
}

.promo-cars--in-car {
  padding-bottom: 40px;

  @media screen and (max-width: @mob) {
    padding-bottom: 30px;
  }

  .promo-cars__title {
    font-size: 40px;

    @media screen and (max-width: @mob) {
      font-size: 24px;
    }
  }
}