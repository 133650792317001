.promo-offer {
  padding-top   : 60px;
  padding-bottom: 60px;
  background    : #F2F5F7;

  @media screen and (max-width: @mob) {
    padding-top   : 44px;
    padding-bottom: 30px;
  }

  &__wrap {
    padding      : 50px 124px 50px;
    box-shadow   : 0px 5px 20px 0px rgba(12, 19, 26, 0.05);
    border-radius: 20px;
    background   : #ffffff;

    @media screen and (max-width: @tablet) {
      padding-left : 70px;
      padding-right: 70px;
    }

    @media screen and (max-width: @mob) {
      padding: 30px 20px 100px;
    }
  }

  &__title {
    text-align    : center;
    padding-bottom: 40px;
    font-size     : 36px;
    font-weight   : 700;
    line-height   : 1.1;

    @media screen and (max-width: @ipad) {
      padding-bottom: 20px;
    }

    @media screen and (max-width: @mob) {
      padding-bottom: 20px;
      font-size     : 24px;
      line-height   : 1.3;
    }

    span {
      color: @color-red;
    }
  }
}